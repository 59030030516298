<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div v-if="showForm" class="row justify-content-center">
        <user-form :user="user" @save="updateUser" />
      </div>
    </div>
  </div>
</template>

<script>
import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb'
import UserForm from '@/components/Forms/UserForm'
import { UPDATE_FIELDS } from '@/support/constants/users.js'
import { ElNotification } from 'element-plus'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import { ref, onMounted, computed } from 'vue'

export default {
  components: {
    RouteBreadCrumb,
    UserForm
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()

    const showForm = ref(false)

    onMounted(() => {
      store.dispatch('users/unsetResource').then(() => {
        store
          .dispatch('users/show', { id: route.params.id, params: {} })
          .then(() => (showForm.value = true))
          .catch(() => router.push({ name: 'Usuarios' }))
      })
    })

    const user = computed(() => store.getters['users/resource'])

    function updateUser(updatedUser) {
      const dirtyFields = UPDATE_FIELDS.reduce((result, key) => {
        if (updatedUser[key] !== user.value[key]) {
          result[key] = updatedUser[key]
        }
        return result
      }, {})

      const userName = updatedUser.username ?? user.value.username

      store
        .dispatch('users/update', { id: route.params.id, ...dirtyFields })
        .then(() => {
          ElNotification({
            title: 'Usuario Modificado',
            message: `El usuario ${userName} ha sido modificado con éxito`,
            type: 'success',
            duration: 6000
          })
        })
        .catch(() => {
          ElNotification({
            title: 'Error',
            message: 'No hemos podido modificar el usuario',
            type: 'error',
            duration: 6000
          })
        })
        .finally(() => {
          router.push({
            name: 'Usuarios'
          })
        })
    }

    return {
      showForm,
      updateUser,
      user
    }
  }
}
</script>

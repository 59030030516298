<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="row justify-content-center">
        <promotion-form :cardTitle="'Crear Promoción'" @save="createPromotion">
        </promotion-form>
      </div>
    </div>
  </div>
</template>

<script>
import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb'
import PromotionForm from '@/components/Forms/PromotionForm'
import { useStore } from 'vuex'
import { ElNotification } from 'element-plus'
import { useRouter } from 'vue-router'

export default {
  components: {
    ElNotification,
    PromotionForm,
    RouteBreadCrumb
  },
  setup() {
    const store = useStore()
    const router = useRouter()

    function createPromotion(promotion) {
      store
        .dispatch('promotions/store', promotion)
        .then((res) => {
          router.push({
            path: `/promociones/${res.data.id}/editar`
          })
          ElNotification({
            title: 'Promoción Creada',
            message: 'La promoción ha sido creada con éxito',
            type: 'success',
            duration: 6000
          })
        })
        .catch(() => {
          ElNotification({
            title: 'Error',
            message: 'No hemos podido crear la entidad',
            type: 'error',
            duration: 6000
          }).then(() =>
            router.push({
              name: 'Promociones'
            })
          )
        })
    }

    return {
      createPromotion
    }
  }
}
</script>

<template>
  <el-input
    v-model="searchQuery"
    class="m-2"
    size="large"
    :placeholder="placeholder"
    @input="handleSearchInput"
  />
</template>

<script>
import { ElInput } from 'element-plus'
import { ref } from 'vue'

export default {
  components: {
    ElInput
  },

  props: {
    debounceTime: {
      default: 2000,
      type: Number
    },
    placeholder: {
      default: '',
      type: String
    }
  },

  setup(props, { emit }) {
    const searchQuery = ref('')

    var lastTermSearch = ''

    function handleSearchInput(newValue) {
      lastTermSearch = newValue

      setTimeout(() => {
        if (lastTermSearch === newValue) {
          emit('search', newValue)
          lastTermSearch = ''
        }
      }, props.debounceTime)
    }

    return {
      handleSearchInput,
      searchQuery
    }
  }
}
</script>

export default {
  request: function (request, token) {
    if (token) {
      const tokens = token.split(';')

      if (tokens.length > 1 && request.url === this.options.refreshData.url) {
        request.data.refresh_token = tokens[1]
      }

      this.drivers.http.setHeaders.call(this, request, { Authorization: 'Bearer ' + tokens[0] })
    }
  },
  response: function (response) {
    const accessToken = response.data[this.options.loginData.tokenName]

    if (accessToken) {
      const tokens = [accessToken]

      if (this.options.refreshData.enabled) {
        tokens.push(response.data[this.options.refreshData.tokenName])
      }
      return tokens.join(';')
    }
  }
}

<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <!-- <h6 class="h2 text-white d-inline-block mb-0">Categorias</h6> -->
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
        <div
          v-if="can('store', 'categories')"
          class="col-lg-6 col-5 text-right"
        >
          <base-button
            @click="$router.push({ path: '/categorias/crear' })"
            size="sm"
            type="neutral"
            >Nueva Categoria</base-button
          >
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col">
          <categories-table
            :loading="loading"
            :categories="categories"
            :meta="categoriesMeta"
            @pageUpdated="fetchList"
            @rowDeletionConfirmed="deleteRow"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb'
import CategoriesTable from './Table'
import { useAbility } from '@casl/vue'
import { useStore } from 'vuex'
import { onMounted, computed } from 'vue'
import { ElNotification } from 'element-plus'

export default {
  components: {
    RouteBreadCrumb,
    CategoriesTable
  },
  setup() {
    const { can } = useAbility()

    const store = useStore()

    onMounted(() => {
      const params = {
        page: 1,
        per_page: 15
      }
      fetchList(params)
    })

    const loading = computed(() => {
      return (store?.state.loader.list || store?.state.loader.destroy) ?? false
    })
    const categories = computed(() => store.getters['categories/list'])
    const categoriesMeta = computed(() => store.getters['categories/meta'])

    function fetchList(params = {}) {
      store.dispatch('categories/list', params)
    }

    function deleteRow({ id }) {
      store.dispatch('categories/destroy', id).then(() => {
        ElNotification({
          title: 'Categoría Eliminada',
          message: 'La categoría ha sido eliminada con éxito',
          type: 'success',
          duration: 6000
        })
      })
    }

    return {
      can,
      deleteRow,
      fetchList,
      loading,
      categories,
      categoriesMeta
    }
  }
}
</script>

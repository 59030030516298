import usersService from '../../services/users.service'
import crud from './utils/crud.util'
import userTypes from './utils/userTypes.utils'

const state = {}

// getters
const getters = {
  service() {
    return usersService
  },
  selectList(state, getters, rootState, rootGetters) {
    return state.list.length > 0
      ? state.list
          .filter(
            (u) =>
              (Boolean(rootGetters['shops/resource']) &&
                u.id === rootGetters['shops/resource'].user_id) ||
              !u.has_shop
          )
          .map((item) => ({ value: item.id, label: item.username }))
      : []
  }
}

// actions
const actions = {}

// mutations
const mutations = {}

export default {
  namespaced: true,
  state: { ...state, ...crud.state },
  getters: { ...getters, ...crud.getters, ...userTypes.getters },
  actions: { ...actions, ...crud.actions, ...userTypes.actions },
  mutations: { ...mutations, ...crud.mutations, ...userTypes.mutations }
}

<template>
  <div class="card">
    <div class="card-header border-0">
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0">Promociones aplicadas</h3>
        </div>
        <div class="col text-right">
          <!-- <a href="#!" class="btn btn-sm btn-primary">Seeeee all</a> -->
        </div>
      </div>
    </div>
    <el-table
      class="table-responsive table-flush"
      header-row-class-name="thead-light"
      highlight-current-row
      row-class-name="promotions-applications-table-row"
      :data="promotionsStats"
    >
      <el-table-column label="Nombre" prop="name" min-width="140px">
      </el-table-column>

      <el-table-column
        label="Confirmadas"
        label-class-name="justify-content-center"
        align="center"
        prop="confirmed"
        min-width="140px"
      >
      </el-table-column>

      <el-table-column
        label="Confirmadas Hoy"
        label-class-name="justify-content-center"
        align="center"
        prop="confirmed_today"
        min-width="170px"
      >
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { ElTable, ElTableColumn } from 'element-plus'

export default {
  name: 'promotions-applications-table',
  components: {
    ElTable,
    ElTableColumn
  },
  setup() {
    const store = useStore()

    const me = computed(() => store.getters['auth/user'])

    const promotionsStats = computed(
      () => store.getters['shops/promotionsStats']
    )

    function fetchPromotionsStats() {
      if (me.value.shop) {
        store.dispatch('shops/fetchShopPromotionsStats', me.value.shop.id)
      }
    }

    onMounted(() => {
      fetchPromotionsStats()
    })

    return {
      promotionsStats
    }
  }
}
</script>

<style lang="scss" scoped>
.promotions-applications-table-row {
  opacity: 1;
  transition: opacity 1s;
}

.promotions-applications-table-row:hover {
  opacity: 0;
}
</style>

<template>
  <div>
    <el-autocomplete
      v-model="state"
      class="w-100"
      :placeholder="`Ingrese ${
        linkableType === 'promotion' ? 'Promoción' : 'Noticia'
      }`"
      value-key="name"
      :fetch-suggestions="querySearch"
      @select="handleSelect"
      fit-input-width
    >
      <template #default="{ item }">
        <div
          v-if="linkableType === 'promotion'"
          class="autocomplete-option-promotion"
        >
          <div class="thumbnail">
            <img :src="item.image_url" :alt="item.name" />
          </div>
          <div class="text">
            <p class="option-title font-weight-bold">
              {{ item.name }}
            </p>
            <p class="description font-italic">
              {{ item.description }}
            </p>
          </div>
        </div>
        <div class="autocomplete-option-news" v-else>
          <div class="text">
            <p class="option-title font-weight-bold">
              {{ item.name }}
            </p>
            <p class="expires-at font-italic">
              Fecha de expiración: {{ item.expires_at }}
            </p>
          </div>
        </div>
      </template>
    </el-autocomplete>
  </div>
</template>

<script>
import { ElAutocomplete, ElIcon } from 'element-plus'
import { useStore } from 'vuex'

import { ref, watch } from 'vue'

export default {
  name: 'notifications-autocomplete',
  components: {
    [ElAutocomplete.name]: ElAutocomplete,
    [ElIcon.name]: ElIcon
  },

  props: {
    linkableType: {
      required: true,
      type: String
    },

    modelValue: {
      required: true
    }
  },

  setup(props, { emit }) {
    const state = ref()

    const store = useStore()

    watch(
      () => props.linkableType,
      () => {
        state.value = ''
        handleSelect({ value: null })
      }
    )

    const allPromotionsSelect = (filter) => {
      return store.getters['promotions/allPromotionsSelect'](filter)
    }

    const allNewsSelect = (filter) => {
      return store.getters['news/allNewsSelect'](filter)
    }

    const querySearch = (searchTerm, cb) => {
      if (props.linkableType === 'news') {
        cb(allNewsSelect(searchTerm))
      }

      if (props.linkableType === 'promotion') {
        cb(allPromotionsSelect(searchTerm))
      }
    }

    const handleSelect = (selected) => {
      emit('update:modelValue', String(selected.value))
    }

    return {
      handleSelect,
      querySearch,
      state
    }
  }
}
</script>

<style lang="scss" scoped>
.autocomplete-option-promotion {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin-bottom: 5px;
  margin-top: 5px;
  column-gap: 10px;

  .thumbnail {
    align-self: center;
    grid-column: 1 / 2;
    height: 100px;
    width: 100px;
    overflow: hidden;
  }

  .thumbnail img {
    width: auto;
    height: 100%;
    object-fit: cover;
  }

  .text {
    grid-column: 2 / 5;

    .option-title {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .description {
      font-size: 0.8rem;
      line-height: 1.4;
      white-space: pre-line;
    }

    p {
      margin-bottom: 0.5rem;
    }
  }
}

.autocomplete-option-news {
  .text {
    p {
      margin-bottom: 0.5rem;
    }

    .expires-at {
      font-size: 0.8rem;
    }
  }
}
</style>

<template>
  <div>
    <el-form
      class="d-flex flex-column align-items-center d-xxl-block"
      ref="formRef"
      :inline="inlineForm"
      :model="form"
    >
      <el-form-item label="Código">
        <el-input v-model="form.code"></el-input>
      </el-form-item>
      <el-form-item>
        <button
          class="btn base-button btn-primary"
          @click="onSubmit"
          :disabled="disableApplyButton"
        >
          Aplicar
        </button>
      </el-form-item>
    </el-form>
    <div class="row error-container">
      <div class="col">
        <p class="text-center text-danger">
          <small>{{ errorMessage }}</small>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { ElForm, ElFormItem, ElInput, ElNotification } from 'element-plus'
import { computed, reactive, ref } from 'vue'
import { useStore } from 'vuex'
import ShopService from '@/services/shops.service'

export default {
  components: {
    ElForm,
    ElFormItem,
    ElInput,
    ElNotification
  },
  props: {
    shopId: {
      required: true
    }
  },
  setup(props, { emit }) {
    const store = useStore()

    const me = computed(() => store.getters['auth/user'])

    const form = reactive({
      code: ''
    })
    const disableApplyButton = computed(() => {
      return form.code.length !== 6
    })

    const errorMessage = ref('')

    function onSubmit() {
      ShopService.applyPromotion(props.shopId, form.code)
        .then((res) => {
          ElNotification({
            title: '¡Confirmada!',
            text: 'La promoción ha sido confirmada',
            type: 'success',
            duration: 3000
          })
          emit('promotionApplied', res.data.data.promotion)
          store.dispatch('shops/fetchShopPromotionsStats', me.value.shop.id)
          errorMessage.value = ''
        })
        .catch((error) => {
          errorMessage.value = error.response.data.message
        })
        .finally(() => {
          form.code = ''
        })
    }
    return {
      disableApplyButton,
      errorMessage,
      form,
      onSubmit
    }
  },

  computed: {
    inlineForm() {
      return window.innerWidth > 1440
    }
  }
}
</script>

<style lang="scss" scoped>
.apply-button {
  color: #fff;
  background-color: #294885;
  border-color: #294885;
}
</style>

<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="row justify-content-center">
				<shop-form  
          @save="createShop">
				</shop-form>
      </div>
    </div>
  </div>
</template>

<script>

  import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb'
  import ShopForm from '@/components/Forms/ShopForm'
  import { useStore } from 'vuex'
  import { ElNotification } from 'element-plus'
  import { useRouter } from 'vue-router'

	export default {
		components:{
      RouteBreadCrumb,
      ShopForm
    },
    setup() {
      const store = useStore()
      const router = useRouter()

      function createShop(shop) {
        store.dispatch('shops/store', shop)
          .then((res) => {
            router.push({
              path: `/locales/${res.data.id}/editar`
            })
            ElNotification({
              title: 'Local Creado',
              message: 'El local ha sido creado con éxito',
              type: 'success',
              duration: 6000
            })
          })
          .catch(() => {
            ElNotification({
              title: 'Error',
              message: 'No hemos podido crear la entidad',
              type: 'error',
              duration: 6000
            })
            .then(()=>
              router.push({
                name: 'Locales'
              })
            )
          })
      }

      return {
        createShop
      }
    },	
	}
</script>

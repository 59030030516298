<template>
  <div>
    <el-date-picker
      v-model="datepickerValue"
      type="daterange"
      unlink-panels
      range-separator="|"
      :start-placeholder="fromText"
      :end-placeholder="toText"
      :disabled-date="disabledDate"
      @change="handleChange"
    >
    </el-date-picker>
    <div class="text-danger invalid-feedback">
      {{ errorMessage || customErrorMessage }}
    </div>
  </div>
</template>

<script>
import { ElDatePicker } from 'element-plus'
import { useField } from 'vee-validate'
import { nextTick, ref } from 'vue'

export default {
  name: 'base-date-range',
  components: {
    [ElDatePicker.name]: ElDatePicker
  },
  props: {
    fromText: {
      type: String,
      default: 'Desde'
    },
    initialValue: {
      default: null
    },
    name: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      default: ''
    },
    toText: {
      type: String,
      default: 'Hasta'
    }
  },
  setup(props) {
    const { value: inputValue, errorMessage, handleChange, meta } = useField(
      props.name,
      undefined,
      {
        initialValue: props.initialValue
      }
    )

    const datepickerValue = ref(props.initialValue)

    const customErrorMessage = ref('')

    const disabledDate = (time) => {
      return time.getTime() < new Date(Date.now() - 864e5)
    }

    return {
      customErrorMessage,
      datepickerValue,
      disabledDate,
      handleChange,
      errorMessage,
      inputValue,
      meta
    }
  },
  watch: {
    datepickerValue(newValue) {
      if (newValue && newValue.length) {
        if (newValue[0].getTime() === newValue[1].getTime()) {
          this.customErrorMessage = 'Ingrese fechas distintas'
          nextTick()
          this.datepickerValue = this.initialValue
        } else {
          this.customErrorMessage = ''
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.error-text {
  color: red;
}
</style>

<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="row justify-content-center">
        <notification-form
          :cardTitle="'Crear Notificación'"
          @save="createNotification"
        >
        </notification-form>
      </div>
    </div>
  </div>
</template>

<script>
import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb'
import NotificationForm from '@/components/Forms/NotificationForm'
import { useStore } from 'vuex'
import { ElNotification } from 'element-plus'
import { useRouter } from 'vue-router'
import { onMounted } from 'vue'

export default {
  components: {
    RouteBreadCrumb,
    NotificationForm
  },
  setup() {
    const store = useStore()
    const router = useRouter()

    onMounted(() => {
      store.dispatch('promotions/all')
      store.dispatch('news/all')
    })

    function createNotification(notification) {
      store
        .dispatch('notifications/store', notification)
        .then(() => {
          ElNotification({
            title: 'Notificación Creada',
            message: 'La Notificación ha sido creada con éxito',
            type: 'success',
            duration: 6000
          })
        })
        .catch(() => {
          ElNotification({
            title: 'Error',
            message: 'No hemos podido crear la entidad',
            type: 'error',
            duration: 6000
          })
        })
        .finally(() => {
          router.push({
            name: 'Notificaciones'
          })
        })
    }

    return {
      createNotification
    }
  }
}
</script>

<template>
  <div>
    <el-time-picker
      v-model="timeSelectValue"
      :clearable="false"
      :disabled-seconds="disabledSeconds"
      @change="handleTimeChange"
      @blur="handleBlur"
    >
    </el-time-picker>
  </div>
</template>

<script>
import { ElTimePicker } from 'element-plus'
import { useField } from 'vee-validate'
import { ref } from 'vue'
import { makeRange } from '@/util/common.js'

export default {
  name: 'base-time-select',
  components: {
    [ElTimePicker.name]: ElTimePicker
  },

  props: {
    modelValue: {
      default: null
    },
    name: {
      required: true,
      type: String
    },
    placeholder: {
      default: 'Ingrese tiempo',
      type: String
    },
    value: {
      default: Date()
    }
  },

  setup(props, { emit }) {
    const { value: inputValue, errorMessage, handleChange, meta } = useField(
      props.name,
      undefined,
      {
        initialValue: props.value
      }
    )

    const timeSelectValue = ref(props.value)

    function disabledSeconds() {
      return makeRange(1, 59)
    }

    function handleTimeChange(event) {
      handleChange(event)
      emit('update:modelValue', timeSelectValue.value)
    }

    function handleBlur() {
      emit('update:modelValue', timeSelectValue.value)
    }

    return {
      disabledSeconds,
      timeSelectValue,
      handleTimeChange,
      handleBlur,
      errorMessage,
      inputValue,
      meta
    }
  }
}
</script>

<style scoped></style>

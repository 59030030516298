import { createRouter, createWebHashHistory } from 'vue-router'

import DashboardLayout from '@/views/Layout/DashboardLayout'
import AuthLayout from '@/views/Pages/AuthLayout'

import Alternative from '../views/Dashboard/AlternativeDashboard.vue'

import Login from '../views/Pages/Login.vue'
import Register from '../views/Pages/Register.vue'
import ForgotPassword from '../views/Pages/ForgotPassword.vue'
import ResetPassword from '../views/Pages/ResetPassword.vue'

import UsersList from '../views/Users/List.vue'
import CreateUser from '../views/Users/Create.vue'
import EditUser from '../views/Users/Edit.vue'

import ShopsList from '../views/Shops/List.vue'
import CreateShop from '../views/Shops/Create.vue'
import EditShop from '../views/Shops/Edit.vue'

import CategoriesList from '../views/Categories/List.vue'
import CreateCategory from '../views/Categories/Create.vue'
import EditCategory from '../views/Categories/Edit.vue'

import PromotionsList from '../views/Promotions/List.vue'
import CreatePromotion from '../views/Promotions/Create.vue'
import EditPromotion from '../views/Promotions/Edit.vue'

import NotificationsList from '../views/Notifications/List.vue'
import CreateNotification from '../views/Notifications/Create.vue'

import NewsList from '../views/News/List.vue'
import CreateNews from '../views/News/Create.vue'
import EditNews from '../views/News/Edit.vue'

const routes = [
  {
    path: '/',
    redirect: '/',
    component: DashboardLayout,
    name: 'Dashboards',
    children: [
      {
        path: '/',
        name: 'Alternative',
        components: { default: Alternative },
        meta: {
          navbarType: 'light',
          auth: ['administrator', 'sudo']
        }
      }
    ]
  },
  {
    path: '/',
    redirect: '/',
    component: AuthLayout,
    children: [
      {
        path: '/login',
        name: 'Login',
        components: { default: Login },
        meta: {
          auth: false
        }
      },
      {
        path: '/register',
        name: 'Register',
        components: { default: Register },
        meta: {
          auth: false
        }
      },
      {
        path: '/forgot-password',
        name: 'Olvido Contraseña',
        components: { default: ForgotPassword },
        meta: {
          auth: false
        }
      },
      {
        path: '/reset-password',
        name: 'Nueva Contraseña',
        components: { default: ResetPassword },
        meta: {
          auth: false
        }
      }
    ]
  },
  {
    path: '/usuarios',
    redirect: '/usuarios',
    component: DashboardLayout,
    name: 'Usuarios',
    children: [
      {
        path: '',
        name: 'Listado de Usuarios',
        meta: {
          navbarType: 'light',
          auth: 'sudo'
        },
        component: UsersList
      },
      {
        path: 'crear',
        name: 'Crear Usuario',
        meta: {
          navbarType: 'light',
          auth: 'sudo'
        },
        component: CreateUser
      },
      {
        path: ':id/editar',
        name: 'Editar Usuario',
        meta: {
          navbarType: 'light',
          auth: 'sudo'
        },
        component: EditUser
      }
    ]
  },
  {
    path: '/locales',
    redirect: '/locales',
    component: DashboardLayout,
    name: 'Locales',
    children: [
      {
        path: '',
        name: 'Listado de Locales',
        meta: {
          navbarType: 'light',
          auth: 'sudo'
        },
        component: ShopsList
      },
      {
        path: 'crear',
        name: 'Crear Local',
        meta: {
          navbarType: 'light',
          auth: 'sudo'
        },
        component: CreateShop
      },
      {
        path: ':id/editar',
        name: 'Editar Local',
        meta: {
          navbarType: 'light',
          auth: 'sudo'
        },
        component: EditShop
      }
    ]
  },
  {
    path: '/categorias',
    redirect: '/categorias',
    component: DashboardLayout,
    name: 'Categorias',
    children: [
      {
        path: '',
        name: 'Listado de Categorias',
        meta: {
          navbarType: 'light',
          auth: 'sudo'
        },
        component: CategoriesList
      },
      {
        path: 'crear',
        name: 'Crear Categoría',
        meta: {
          navbarType: 'light',
          auth: 'sudo'
        },
        component: CreateCategory
      },
      {
        path: ':id/editar',
        name: 'Editar Categoría',
        meta: {
          navbarType: 'light',
          auth: 'sudo'
        },
        component: EditCategory
      }
    ]
  },
  {
    path: '/promociones',
    redirect: '/promociones',
    component: DashboardLayout,
    name: 'Promociones',
    children: [
      {
        path: '',
        name: 'Listado de Promociones',
        meta: {
          navbarType: 'light',
          auth: 'sudo'
        },
        component: PromotionsList
      },
      {
        path: 'crear',
        name: 'Crear Promoción',
        meta: {
          navbarType: 'light',
          auth: 'sudo'
        },
        component: CreatePromotion
      },
      {
        path: ':id/editar',
        name: 'Editar Promoción',
        meta: {
          navbarType: 'light',
          auth: 'sudo'
        },
        component: EditPromotion
      }
    ]
  },
  {
    path: '/notificaciones',
    redirect: '/notificaciones',
    component: DashboardLayout,
    name: 'Notificaciones',
    children: [
      {
        path: '',
        name: 'Listado de Notificaciones',
        meta: {
          navbarType: 'light',
          auth: 'sudo'
        },
        component: NotificationsList
      },
      {
        path: 'crear',
        name: 'Crear Notificación',
        meta: {
          navbarType: 'light',
          auth: 'sudo'
        },
        component: CreateNotification
      }
    ]
  },
  {
    path: '/noticias',
    redirect: '/noticias',
    component: DashboardLayout,
    name: 'Noticias',
    children: [
      {
        path: '',
        name: 'Listado de Noticias',
        meta: {
          auth: 'sudo',
          navbarType: 'light'
        },
        component: NewsList
      },
      {
        path: 'crear',
        name: 'Crear Noticia',
        meta: {
          navbarType: 'light',
          auth: 'sudo'
        },
        component: CreateNews
      },
      {
        path: ':id/editar',
        name: 'Editar Noticia',
        meta: {
          navbarType: 'light',
          auth: 'sudo'
        },
        component: EditNews
      }
    ]
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  linkActiveClass: 'active',
  routes
})

export default (app) => {
  app.router = router
  app.use(router)
}

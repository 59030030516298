export const makeRange = (start, end) => {
  const result = []
  for (let i = start; i <= end; i++) {
    result.push(i)
  }
  return result
}

// Date type
export const getTime = (dateVar) => {
  return `${twoDigitTimeFormat(dateVar.getHours())}:${twoDigitTimeFormat(
    dateVar.getMinutes()
  )}`
}

const twoDigitTimeFormat = (value) => {
  return value < 10 ? `0${value}` : `${value}`
}

import ApiService from './api/base.service'

class ShopService extends ApiService {
  constructor () {
    super('shops')
  }

  addCategory (shopId, categoryId) {
    return this.$http.post(`${this.getResource({})}/${shopId}/categories/${categoryId}`).catch(this.handleErrors)
  }

  applyPromotion (shopId, code) {
    return this.$http.patch(`${this.getResource({})}/${shopId}/confirmed-promotions`, { code } ).catch(this.handleErrors)
  }

  fetchShopPromotionsStats (shopId) {
    return this.$http.get(`${this.getResource({})}/${shopId}/stats`).catch(this.handleErrors)
  }

  removeCategory (shopId, categoryId) {
    return this.$http.delete(`${this.getResource({})}/${shopId}/categories/${categoryId}`).catch(this.handleErrors)
  }

  getQrCode (id) {
    return this.$http.get(`${this.getResource({})}/${id}/qr`).catch(this.handleErrors)
  }

  uploadImage (shopId, formData) {
    return this.$http.post(`${this.getResource({})}/${shopId}/images`, formData).catch(this.handleErrors)
  }

}

const usersService = new ShopService()

export default usersService

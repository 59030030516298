const state = {
  all: [],
  list: [],
  meta: {},
  resource: null
}

const getters = {
  list(state) {
    return state.list
  },

  meta(state) {
    return state.meta
  },

  resource(state) {
    return state.resource
  }
}

const actions = {
  all({ commit, getters }) {
    getters.service.index().then(({ data }) => {
      commit('SET_ALL', data.data)
    })
  },

  list({ commit, dispatch, getters }, params) {
    dispatch(
      'loader/setLoading',
      { loadingValue: true, type: 'list' },
      { root: true }
    )
    return getters.service
      .index(params)
      .then(({ data }) => {
        commit('SET_LIST', data.data)
        commit('SET_META', data.meta)
      })
      .finally(() =>
        dispatch(
          'loader/setLoading',
          { loadingValue: false, type: 'list' },
          { root: true }
        )
      )
  },

  show({ commit, getters }, { id, params }) {
    return getters.service.show(id, params).then(({ data }) => {
      commit('SET_RESOURCE', data.data)
    })
  },

  store({ commit, dispatch, getters }, params, config = {}) {
    dispatch(
      'loader/setLoading',
      { loadingValue: true, type: 'store' },
      { root: true }
    )
    return getters.service
      .store(params, config)
      .then(({ data }) => {
        commit('SET_RESOURCE', data.data)
        return data
      })
      .finally(() =>
        dispatch(
          'loader/setLoading',
          { loadingValue: false, type: 'store' },
          { root: true }
        )
      )
  },

  unsetResource({ commit }) {
    commit('UNSET_RESOURCE')
    return Promise.resolve(true)
  },

  update({ commit, dispatch, getters }, params) {
    dispatch(
      'loader/setLoading',
      { loadingValue: true, type: 'update' },
      { root: true }
    )
    return getters.service
      .update(params.id, params)
      .then(({ data }) => {
        commit('SET_RESOURCE', data.data)
        return data
      })
      .finally(() =>
        dispatch(
          'loader/setLoading',
          { loadingValue: false, type: 'update' },
          { root: true }
        )
      )
  },

  attach({ commit, dispatch, getters }, { id, data }) {
    dispatch(
      'loader/setLoading',
      { loadingValue: true, type: 'attach' },
      { root: true }
    )
    return getters.service
      .attach(id, data)
      .then(({ data }) => {
        commit('SET_RESOURCE', data)
      })
      .finally(() =>
        dispatch(
          'loader/setLoading',
          { loadingValue: false, type: 'attach' },
          { root: true }
        )
      )
  },

  destroy({ commit, dispatch, getters }, id) {
    dispatch(
      'loader/setLoading',
      { loadingValue: true, type: 'destroy' },
      { root: true }
    )
    return getters.service
      .destroy(id)
      .then(() => {
        commit('UNSET_ITEM', id)
      })
      .finally(() =>
        dispatch(
          'loader/setLoading',
          { loadingValue: false, type: 'destroy' },
          { root: true }
        )
      )
  },

  destroyWithReason({ commit, getters }, { id, payload }) {
    return getters.service.destroy(id, payload).then(() => {
      commit('UNSET_ITEM', id)
    })
  }
}

const mutations = {
  SET_ALL: (state, all) => {
    state.all = all
  },

  SET_LIST: (state, list) => {
    state.list = list
  },

  SET_RESOURCE: (state, resource) => {
    state.resource = resource
  },

  UNSET_RESOURCE: (state) => {
    state.resource = null
  },

  SET_META: (state, meta) => {
    state.meta = meta
  },

  UNSET_ITEM: (state, id) => {
    const indexToDelete = state.list.findIndex((item) => item.id === id)

    if (indexToDelete >= 0) {
      state.list.splice(indexToDelete, 1)
    }
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}

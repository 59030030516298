<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="row justify-content-center">
				<news-form  @save="createNews">
				</news-form>
      </div>
    </div>
  </div>
</template>

<script>

  import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb'
  import NewsForm from '@/components/Forms/NewsForm'
  import { useStore } from 'vuex'
  import { ElNotification } from 'element-plus'
  import { useRouter } from 'vue-router'

	export default {
		components:{
      RouteBreadCrumb,
      NewsForm
    },
    setup() {
      const store = useStore()
      const router = useRouter()

      function createNews(news) {
        store.dispatch('news/store', news)
          .then(() => {
            ElNotification({
              title: 'Noticias Creado',
              message: 'La noticia ha sido creada con éxito',
              type: 'success',
              duration: 6000
            })
          })
          .catch(() => {
            ElNotification({
              title: 'Error',
              message: 'No hemos podido crear la entidad',
              type: 'error',
              duration: 6000
            })
          })
          .finally( () => {
            router.push({
              name: 'Noticias'
            })
          })
      }

      return {
        createNews
      }
    },	
	}
</script>

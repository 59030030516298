import { reactive } from 'vue'

export default function () {

  function setFormData (category) {
    return reactive({
      name: category ? category.name : null,
      description: category ? category.description : null,
    })
  }

  return {
    setFormData
  }
} 
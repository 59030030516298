<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="breadcrumb-title h2 d-inline-block mb-0">Home</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <!-- <route-bread-crumb></route-bread-crumb> -->
          </nav>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--6">
      <div v-if="me && me.shop" class="row">
        <div
          :class="{
            'col-lg-6': shopValidatesCode,
            'col-xl-8': shopValidatesCode,
            'col-12': !shopValidatesCode
          }"
        >
          <promotions-applications-table />
        </div>
        <div v-if="shopValidatesCode" class="col-lg-6 col-xl-4">
          <confirm-promotion-card />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ConfirmPromotionCard from '@/components/Cards/ConfirmPromotionCard'
import PromotionsApplicationsTable from '@/components/Tables/PromotionsApplicationsTable'
import { computed } from 'vue'
import { useStore } from 'vuex'

export default {
  components: {
    ConfirmPromotionCard,
    PromotionsApplicationsTable
  },
  setup() {
    const store = useStore()

    const me = computed(() => store.getters['auth/user'])

    const shopValidatesCode = computed(() =>
      Boolean(me.value?.shop.validate_code)
    )

    return {
      me,
      shopValidatesCode
    }
  }
}
</script>

<style lang="scss" scoped>
.breadcrumb-title {
  color: white;
}
</style>

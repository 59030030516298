import shopsService from '../../services/shops.service'
import crud from './utils/crud.util'
import categories from './utils/categories.utils'

const state = {
  list: [],
  meta: {},
  promotionsStats: [],
  qrCode: null,
  resource: {}
}

// getters
const getters = {
  qrCode(state) {
    return state.qrCode
  },
  promotionsStats(state) {
    return state.promotionsStats
  },
  service() {
    return shopsService
  },
  options(state) {
    return state.list.length
      ? state.list.map((shop) => ({ label: shop.name, value: shop.id }))
      : []
  }
}

// actions
const actions = {
  getQrCode({ commit, getters }, params) {
    return getters.service.getQrCode(params).then(({ data }) => {
      commit('SET_QR', data.data)
    })
  },
  fetchShopPromotionsStats({ commit, getters }, shopId) {
    return getters.service
      .fetchShopPromotionsStats(shopId)
      .then((res) => commit('SET_STATS', res.data.data))
  }
}

// mutations
const mutations = {
  SET_QR: (state, qrCode) => {
    state.qrCode = qrCode
  },
  SET_STATS: (state, promotionsStats) => {
    state.promotionsStats = promotionsStats
  }
}

export default {
  namespaced: true,
  state,
  getters: { ...crud.getters, ...categories.getters, ...getters },
  actions: { ...actions, ...crud.actions, ...categories.actions },
  mutations: { ...mutations, ...crud.mutations, ...categories.mutations }
}

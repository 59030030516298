<template>
  <div class="col-10 card-wrapper">
    <div class="card mb-4">
      <!-- Card header -->
      <div class="card-header">
        <h3 class="mb-0">{{ this.cardTitle }}</h3>
      </div>
      <!-- Card body -->
      <div class="card-body">
        <div class="row">
          <div class="mb-3 col-xxxl-4">
            <div v-if="promotion">
              <h4 class="text-center mb-4">Imagen</h4>

              <image-uploader
                :key="refreshKeyImage"
                :image="promotion.image_url === null ? '' : promotion.image_url"
                :loading="uploadingImage"
                @uploadImage="uploadImage"
              />
            </div>
          </div>
          <div class="col-xxxl-8">
            <Form
              @submit="onSubmit"
              :validation-schema="schema"
              class="needs-validation"
            >
              <div class="row">
                <div class="col-md-6 col-sm-12">
                  <div class="form-group">
                    <label class="control-label"> Nombre </label>
                    <base-input
                      name="name"
                      placeholder="Nombre"
                      :value="formData.name"
                    >
                    </base-input>
                  </div>
                </div>

                <div class="col-md-6 col-sm-6">
                  <div v-show="!formData.allDays" class="form-group">
                    <label class="control-label"> Día de la semana </label>
                    <base-select
                      placeholder="Seleccione los días"
                      multiple
                      :name="'days'"
                      :initialValue="formData.days"
                      :options="weekDays"
                    />
                  </div>
                </div>

                <div class="col-md-6 col-sm-12">
                  <div class="form-group">
                    <label class="control-label"> Descuento (%) </label>
                    <base-input-number
                      name="discount"
                      :min="0"
                      :max="100"
                      :precision="2"
                      :value="formData.discount"
                    />
                  </div>
                </div>

                <div class="col-md-6 col-sm-6">
                  <div class="form-group mt-2">
                    <label class="control-label"></label>
                    <base-checkbox
                      label="Todos los días de la semana"
                      name="allDays"
                      v-model="formData.allDays"
                      :value="formData.allDays"
                    />
                  </div>
                </div>

                <div class="col-md-6 col-sm-12">
                  <div class="form-group">
                    <label class="control-label"> Rango de validez </label>
                    <base-date-range
                      :name="'from_to'"
                      :initialValue="formData.from_to"
                    />
                  </div>
                </div>

                <div class="col-md-6 col-sm-12">
                  <div class="form-group">
                    <label class="control-label"> Descripción </label>
                    <base-input
                      name="description"
                      placeholder="Descripción"
                      :value="formData.description"
                    >
                    </base-input>
                  </div>
                </div>

                <div class="col-md-6 col-sm-12">
                  <div class="form-group">
                    <label class="control-label"> Términos </label>
                    <base-input
                      name="terms"
                      placeholder="Términos"
                      :value="formData.terms"
                    >
                    </base-input>
                  </div>
                </div>

                <div class="col-md-6 col-sm-6">
                  <div class="form-group">
                    <label class="control-label"> Locales </label>
                    <base-select
                      v-if="shopOptions.length"
                      placeholder="Seleccione los locales asociados"
                      multiple
                      :name="'shops'"
                      :initialValue="formData.shops"
                      :options="shopOptions"
                    />
                  </div>
                </div>

                <div v-if="promotion" class="col-12">
                  <div class="form-group">
                    <label class="control-label"> Tipos de Usuario </label>
                    <tags-selector
                      :entityName="'Tipo de Usuario'"
                      :entityPluralName="'Tipos de Usuarios'"
                      :entityGender="'M'"
                      :options="userTypeOptions"
                      :selectedValues="userTypeSelectedValues"
                      :theme="'blue'"
                      :vuexResource="'promotions/userTypes'"
                    />
                  </div>
                </div>
                <div class="col-md-12 text-right">
                  <base-button
                    native-type="submit"
                    type="primary"
                    :loading="saving"
                    >Guardar</base-button
                  >
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as Yup from 'yup'
import { ref } from 'vue'
import { Form } from 'vee-validate'
import { useStore } from 'vuex'
import { onMounted, computed } from 'vue'
import { ElNotification } from 'element-plus'
import BaseCheckbox from '../CustomInputs/BaseCheckbox'
import BaseSelect from '../CustomInputs/BaseSelect'
import BaseDateRange from '../CustomInputs/BaseDateRange'
import BaseInputNumber from '../CustomInputs/BaseInputNumber'
import TagsSelector from '../CustomInputs/TagsSelector'
import usePromotionComposable from '../../composables/promotionComposable'
import useWeekComposable from '../../composables/weekComposable'
import ImageUploader from '@/components/CustomInputs/ImageUploader'
import promotionService from '@/services/promotions.service'
import { useRoute } from 'vue-router'

export default {
  name: 'promotion-form',
  components: {
    BaseCheckbox,
    BaseDateRange,
    BaseInputNumber,
    BaseSelect,
    Form,
    ImageUploader,
    TagsSelector
  },
  props: {
    cardTitle: {
      type: String,
      default: 'Crear Promoción'
    },
    promotion: {
      type: Object,
      default: null
    }
  },
  setup(props, { emit }) {
    const store = useStore()

    const route = useRoute()

    // Shops and UserTypes

    onMounted(async () => {
      const params = {
        sort: 'name'
      }
      await store.dispatch('shops/list')
      store.dispatch('userTypes/list', params)
    })

    const userTypeSelectedValues = computed(
      () => store.getters['promotions/userTypesIds']
    )
    const userTypeOptions = computed(
      () => store.getters['userTypes/selectList']
    )
    const shopOptions = computed(() => store.getters['shops/options'])

    //Set Form

    const { setFormData } = usePromotionComposable()

    const formData = setFormData(props.promotion)

    const uploadingImage = ref(false)

    const schema = {
      name: Yup.string().required().label('Username'),
      description: Yup.string().required().label('description'),
      terms: Yup.string().required().label('terms'),
      from_to: Yup.array().of(Yup.date()).label('terms'),
      discount: Yup.string().required().label('terms'),
      shops: Yup.array()
        .min(1, 'Al menos un local debe ser seleccionado')
        .required()
        .label('shops')
    }

    const saving = computed(() => {
      return (store?.state.loader.store || store?.state.loader.update) ?? false
    })

    function onSubmit(values) {
      const {
        description,
        discount,
        from,
        from_to,
        name,
        shops,
        terms,
        to
      } = values
      emit('save', {
        days: formData.allDays ? null : values.days,
        description,
        discount,
        from,
        from_to,
        name,
        shops,
        terms,
        to
      })
    }

    const { weekDays } = useWeekComposable()

    const refreshKeyImage = ref(1)

    function uploadImage(image) {
      uploadingImage.value = true
      const formDataImage = new FormData()
      formDataImage.append('image', image)
      promotionService
        .uploadImage(route.params.id, formDataImage)
        .then((res) => {
          ElNotification({
            title: '¡Imagen Subida!',
            type: 'success',
            duration: 3000
          })
          emit('imageUpdated', res.data.data)
          refreshKeyImage.value++
        })
        .catch(() => {
          ElNotification({
            title: 'Error',
            type: 'error',
            duration: 3000
          })
        })
        .finally(() => {
          uploadingImage.value = false
        })
    }

    return {
      formData,
      onSubmit,
      refreshKeyImage,
      saving,
      schema,
      shopOptions,
      uploadingImage,
      uploadImage,
      userTypeOptions,
      userTypeSelectedValues,
      weekDays
    }
  }
}
</script>

<template>
  <div>
    <div v-if="selectedValues && selectedValues.length && options && options.length > 0" class="row">
      <div class="col">
        <p>
          {{ entityPluralName }} {{ entityGender === 'F' ? 'seleccionadas' : 'seleccionados' }} :
        </p>
        <ul>
          <li class="text-uppercase" v-for="(value, index) in selectedValues" :key="value" >
            {{ getEntityName(value) }}
          </li>
        </ul>
      </div>
    </div>
    <div class="row">
      <div class="col d-flex flex-wrap">
        <option-tag
          v-for="(optionTag, index) in options"
          :entityGender="entityGender"
          :entityId="optionTag.value"
          :entityName="entityName"
          :key="optionTag.label"
          :label="optionTag.label"
          :vuexResource="vuexResource"
          :tagValue="selectedValues.includes(optionTag.value)"
          :theme="theme"
        />
      </div>
    </div>
  </div>
</template>

<script>

import optionTag from '../Layout/OptionTag'
import { toRefs } from 'vue'

export default {
  name:'tags-selector',
  components: {
    optionTag
  },
  props: {
    entityName: {
      type: String,
      default: 'Entidades'
    },
    entityPluralName: {
      type: String,
      default: 'Entidad'
    },
    entityGender: {
      type: String,
      default: 'F'
    },
    options: {
      description: 'Options with value and label properties',
      required: true,
    },
    selectedValues: {
      description: 'Array of the selected ids entities',
      default: []
    },
    theme: {
      default: 'green'
    },
    vuexResource: {
      type: String,
      required: true
    }
  },
  setup (props) {

    function getEntityName(id) {
      return props.options.find( o => o.value === id).label
    }

    return {
      getEntityName
    }
  }
}
</script>

<style>

</style>
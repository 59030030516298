const getters = {
  categoriesIds(state) {
    return state.resource && state.resource.categories
      ? state.resource.categories.map((c) => c.id)
      : null
  }
}

const actions = {
  categoriesUpdate({ commit, getters, rootGetters }, { id, tagNewValue }) {
    if (tagNewValue) {
      return getters.service.addCategory(getters.resource.id, id).then(() => {
        const category = rootGetters['categories/list'].find((c) => c.id === id)
        commit('ADD_CATEGORY_TO_RESOURCE', category)
      })
    } else {
      return getters.service
        .removeCategory(getters.resource.id, id)
        .then(() => {
          const categoryIndex = getters.resource.categories.map(cat => cat.id).indexOf(id)
          commit('REMOVE_CATEGORY_FROM_RESOURCE', categoryIndex)
        })
    }
  }
}

const mutations = {
  ADD_CATEGORY_TO_RESOURCE: (state, category) => {
    state.resource.categories.push(category)
  },
  REMOVE_CATEGORY_FROM_RESOURCE: (state, categoryIndex) => {
    state.resource.categories.splice(categoryIndex,1)
  }
}

export default {
  getters,
  actions,
  mutations
}
<template>
  <div class="col-10 card-wrapper">
    <div class="card mb-4">
      <!-- Card header -->
      <div class="card-header">
        <h3 class="mb-0">{{ cardTitle }}</h3>
      </div>
      <!-- Card body -->
      <div class="card-body">
        <div class="row">
          <div class="mb-3 col-xxxl-4">
            <div v-if="shop">
              <h4 class="text-center mb-4">Logo</h4>

              <image-uploader
                :key="refreshKeyLogoImage"
                :image="shop.logo_url === null ? '' : shop.logo_url"
                :loading="uploadingLogoImage"
                @uploadImage="uploadLogoImage"
              />

              <h4 class="text-center mt-5 mb-4">Imagen Principal</h4>

              <image-uploader
                :key="refreshKeyMainImage"
                :image="shop.main_image_url === null ? '' : shop.main_image_url"
                :loading="uploadingMainImage"
                @uploadImage="uploadMainImage"
              />
            </div>
          </div>
          <div class="col-xxxl-8">
            <Form
              @submit="onSubmit"
              :validation-schema="schema"
              class="needs-validation"
            >
              <div class="row">
                <div class="col-sm-12">
                  <div class="form-group">
                    <label class="control-label"> Nombre </label>
                    <base-input
                      name="name"
                      placeholder="Nombre"
                      :value="formData.name"
                    >
                    </base-input>
                  </div>
                </div>

                <div class="col-sm-12">
                  <div class="form-group">
                    <label class="control-label"> Descripción </label>
                    <base-textarea
                      name="description"
                      placeholder="Descripción"
                      :value="formData.description"
                    >
                    </base-textarea>
                  </div>
                </div>

                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="control-label"> Usuario / Dueño </label>
                    <base-select
                      :name="'user_id'"
                      :initialValue="formData.user_id"
                      :options="users"
                      :placeholder="'Elegir dueño'"
                    />
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="control-label"> QR </label>
                    <base-checkbox
                      label="Validar código"
                      name="validate_code"
                      :value="formData.validate_code"
                      v-model="formData.validate_code"
                    />
                  </div>
                </div>

                <div v-if="shop && categoriesSelectedValues" class="col-12">
                  <div class="form-group">
                    <label class="control-label"> Categorías </label>
                    <tags-selector
                      :entityName="'Categoría'"
                      :entityPluralName="'Categorías'"
                      :entityGender="'F'"
                      :options="categoriesOptions"
                      :selectedValues="categoriesSelectedValues"
                      :vuexResource="'shops/categories'"
                    />
                  </div>
                </div>

                <div class="col-md-12 text-right">
                  <base-button
                    :loading="saving"
                    native-type="submit"
                    type="primary"
                    >Guardar</base-button
                  >
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as Yup from 'yup'
import { ref } from 'vue'
import { Form } from 'vee-validate'
import { useStore } from 'vuex'
import { onMounted, computed } from 'vue'
import BaseSelect from '../CustomInputs/BaseSelect'
import BaseTextarea from '../CustomInputs/BaseTextarea'
import BaseCheckbox from '../CustomInputs/BaseCheckbox'
import useShopComposable from '../../composables/shopComposable'
import TagsSelector from '../CustomInputs/TagsSelector'
import ImageUploader from '@/components/CustomInputs/ImageUploader'
import shopService from '@/services/shops.service'
import { useRoute } from 'vue-router'
import { ElNotification } from 'element-plus'

export default {
  name: 'shop-form',
  components: {
    BaseCheckbox,
    BaseSelect,
    BaseTextarea,
    Form,
    ImageUploader,
    TagsSelector
  },
  props: {
    cardTitle: {
      type: String,
      default: 'Crear Local'
    },
    shop: {
      type: Object,
      default: null
    }
  },
  setup(props, { emit }) {
    const { setFormData } = useShopComposable()

    const store = useStore()

    const route = useRoute()

    const formData = setFormData(props.shop)

    const users = computed(() => store.getters['users/selectList'])

    const schema = {
      name: Yup.string().required().label('Nombre'),
      description: Yup.string().required().label('Descripción'),
      user_id: Yup.string().required().label('Usuario/Dueño')
    }

    onMounted(() => {
      const params = {
        sort: 'name'
      }
      store.dispatch('users/list', { 'filter[only_admins]': 1 })
      store.dispatch('categories/list', params)
    })

    function onSubmit(values) {
      emit('save', { ...values, validate_code: formData.validate_code })
    }

    const saving = computed(() => {
      return (store?.state.loader.store || store?.state.loader.update) ?? false
    })

    //Shop categories

    const categoriesSelectedValues = computed(
      () => store.getters['shops/categoriesIds']
    )
    const categoriesOptions = computed(
      () => store.getters['categories/selectList']
    )

    //images

    const refreshKeyMainImage = ref(1)
    const refreshKeyLogoImage = ref(1)

    const uploadingLogoImage = ref(false)
    const uploadingMainImage = ref(false)

    function uploadMainImage(image) {
      uploadingMainImage.value = true
      const formDataImage = new FormData()
      formDataImage.append('main_image', image)
      shopService
        .uploadImage(route.params.id, formDataImage)
        .then((res) => {
          emit('mainImageUpdated', res.data.data)
          ElNotification({
            title: '¡Imagen Subida!',
            type: 'success',
            duration: 3000
          })
          refreshKeyMainImage.value++
        })
        .catch(() => {
          ElNotification({
            title: 'Error',
            type: 'error',
            duration: 3000
          })
        })
        .finally(() => (uploadingMainImage.value = false))
    }

    function uploadLogoImage(image) {
      uploadingLogoImage.value = true
      const formDataImage = new FormData()
      formDataImage.append('logo', image)
      shopService
        .uploadImage(route.params.id, formDataImage)
        .then((res) => {
          ElNotification({
            title: '¡Imagen Subida!',
            type: 'success',
            duration: 3000
          })
          emit('logoImageUpdated', res.data.data)
          refreshKeyLogoImage.value++
        })
        .catch(() => {
          ElNotification({
            title: 'Error',
            type: 'error',
            duration: 3000
          })
        })
        .finally(() => (uploadingLogoImage.value = false))
    }

    return {
      categoriesOptions,
      categoriesSelectedValues,
      formData,
      onSubmit,
      refreshKeyLogoImage,
      refreshKeyMainImage,
      uploadingLogoImage,
      uploadingMainImage,
      uploadLogoImage,
      uploadMainImage,
      users,
      schema,
      saving
    }
  }
}
</script>

import notificationsService from '../../services/notifications.service'
import crud from './utils/crud.util'

const state = {}

// getters
const getters = {
  service() {
    return notificationsService
  }
}

// actions
const actions = {}

// mutations
const mutations = {}

export default {
  namespaced: true,
  state: { ...state, ...crud.state },
  getters: { ...getters, ...crud.getters },
  actions: { ...actions, ...crud.actions },
  mutations: { ...mutations, ...crud.mutations }
}

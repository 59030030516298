const state = {
  all: false,
  list: false,
  show: false,
  store: false,
  update: false,
  attach: false,
  destroy: false
}

const actions = {
  setLoading({ commit }, { loadingValue, type }) {
    commit('SET_LOADING', { loadingValue, type })
  }
}

const mutations = {
  SET_LOADING(state, { loadingValue, type }) {
    state[type] = loadingValue
  }
}

export default {
  namespaced: true,
  actions,
  mutations,
  state
}

import ApiService from './api/base.service'

class NewsService extends ApiService {
  constructor () {
    super('news')
  }

  updateNews (id, payload, params = {}) {
    return this.$http.post(this.getResource({ id }), this.map(payload), { params: params }).catch(this.handleErrors)
  }
}

const newsService = new NewsService()

export default newsService

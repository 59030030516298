import rolesService from '../../services/roles.service'
import crud from './utils/crud.util'


const state = {
  list: [],
  resource: {},
  meta: {}
}

// getters
const getters = {
  service () {
    return rolesService
  },

  selectList (state) {
    return state.list.length > 0 ? state.list.map(item => { return { value: item.id, label:item.label } }) : []
  }
}

// actions
const actions = {}

// mutations
const mutations = {}

export default {
  namespaced: true,
  state,
  getters: { ...getters, ...crud.getters },
  actions: { ...actions, ...crud.actions },
  mutations: { ...mutations, ...crud.mutations }
}
import { ref } from 'vue'

import { sortOrders } from '../support/constants/general'

export default function () {
  const search = ref('')
  const sort = ref({
    prop: null,
    order: null
  })

  const currentPage = ref(1)

  const perPage = ref(15)

  function getListParameters() {
    const pageParameters = {
      page: currentPage.value,
      per_page: perPage.value
    }

    const parameters = { ...pageParameters }

    if (search.value) {
      parameters['filter[search]'] = search.value
    }

    if (sort.value.prop) {
      parameters['sort'] =
        sort.value.order === sortOrders.ascending
          ? sort.value.prop
          : `-${sort.value.prop}`
    }

    return parameters
  }

  return {
    currentPage,
    getListParameters,
    perPage,
    search,
    sort
  }
}

import ApiService from './api/base.service'
import moment from 'moment'

class NotificationService extends ApiService {
  constructor() {
    super('notifications')
  }

  map(payload) {
    const from =
      payload.frequency === 'once'
        ? moment(payload.once_date)
        : moment(payload.from_to[0])
    const to =
      payload.frequency === 'once'
        ? moment(payload.once_date)
        : moment(payload.from_to[1])

    delete payload.from_to
    delete payload.once_date

    return {
      ...payload,
      from: from.format('YYYY-MM-DD'),
      to: to.format('YYYY-MM-DD')
    }
  }
}

const notificationsService = new NotificationService()

export default notificationsService

<template>
  <div>
    <el-select
      :multiple="multiple"
      :id="name"
      :name="name"
      :placeholder="placeholder"
      v-model="selectValue"
      @change="handleSelectChange"
    >
      <el-option
        v-for="option in options"
        :key="option.value"
        :label="option.label"
        :value="option.value"
      >
      </el-option>
    </el-select>
    <div class="text-danger invalid-feedback">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
import { ElSelect, ElOption } from 'element-plus'
import { useField } from 'vee-validate'
import { ref } from 'vue'

export default {
  name: 'base-select',
  components: {
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption
  },
  props: {
    modelValue: {
      default: null
    },
    multiple: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      required: true
    },
    options: {
      type: Array,
      required: true
    },
    placeholder: {
      type: String,
      default: ''
    },
    initialValue: {
      default: null
    }
  },
  setup(props, { emit }) {
    const { value: inputValue, errorMessage, handleChange, meta } = useField(
      props.name,
      undefined,
      {
        initialValue: props.initialValue
      }
    )

    const selectValue = ref(props.initialValue)

    function handleSelectChange(event) {
      handleChange(event)
      emit('update:modelValue', String(selectValue.value))
    }

    return {
      handleSelectChange,
      errorMessage,
      inputValue,
      meta,
      selectValue
    }
  }
}
</script>

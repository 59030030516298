import axios from 'axios';

axios.defaults.baseURL = process.env.VUE_APP_API_URL;

export default (app) => {
	axios.defaults.headers.common['Accept'] = 'application/json'
	axios.defaults.headers.common['Content-Type'] = 'application/json'
	axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
	axios.defaults.headers.common['Response-Type'] = 'application/json'

	app.axios = axios;
	app.$http = axios;

	app.config.globalProperties.axios = axios;
	app.config.globalProperties.$http = axios;
}
<template>
  <div>
    <el-input-number 
      v-model="inputNumberValue" 
      :min="min"
      :max="max"
      :precision="precision"
      :step="step"
      @change="handleChange" 
    />
    <div>
      {{ errorMessage }}
    </div>
  </div>
  
</template>

<script>

import { ElInputNumber } from "element-plus";
import { useField } from "vee-validate"
import { ref } from "vue"

export default {
  name: 'base-select',
  components: {
    [ElInputNumber.name]: ElInputNumber
  },
  props: {
    initialValue: {
      default: 1,
    },
    max: {
      type: Number,
      default: 10,
    },
    min: {
      type: Number,
      default: 1,
    },
    name: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: ''
    },
    precision: {
      type: Number,
      default: 0,
    },
    step: {
      type: Number,
      default: 1,
    },
    value: {
      type: Number,
      default: 1
    }
  },
  setup(props) {
    const { value: inputValue, errorMessage, handleChange, meta } = useField(
      props.name,
      undefined,
      {
        initialValue: props.value
      }
    )

    const inputNumberValue = ref(props.value)

    return {
      inputNumberValue,
      handleChange,
      errorMessage,
      inputValue,
      meta
    };
  }
}
</script>

<template>
  <div class="card">
    <applied-promotion-modal
      v-if="appliedPromotion.promotion"
      :promotion="appliedPromotion.promotion"
      :show="showModal"
      @closeButtonClicked="closeModal"
    />
    <div class="card-header">
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0">Aplicar Promoción</h3>
        </div>
        <div class="col text-right"></div>
      </div>
    </div>
    <div>
      <div class="row">
        <div class="col">
          <apply-promotion-form
            :shopId="myShop.id"
            class="d-md-flex justify-content-md-center mt-4"
            @promotionApplied="showAppliedPromotionModal"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApplyPromotionForm from '@/components/Forms/ApplyPromotionForm'
import AppliedPromotionModal from '@/components/Modal/AppliedPromotionModal'
import { useStore } from 'vuex'
import { computed, reactive, ref } from 'vue'

export default {
  name: 'confirm-promotion-card',
  components: {
    AppliedPromotionModal,
    ApplyPromotionForm
  },
  setup() {
    const store = useStore()

    const showModal = ref(false)

    const appliedPromotion = reactive({ promotion: null })

    const myShop = computed(() => store.getters['auth/myShop'])

    function showAppliedPromotionModal(promotion) {
      appliedPromotion.promotion = promotion
      showModal.value = true
    }

    function closeModal() {
      showModal.value = false
      appliedPromotion.promotion = null
    }

    return {
      appliedPromotion,
      closeModal,
      myShop,
      showAppliedPromotionModal,
      showModal
    }
  }
}
</script>

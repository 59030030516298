<template>
  <div class="col-10 card-wrapper">
    <div class="card mb-4">
      <!-- Card header -->
      <div class="card-header">
        <h3 class="mb-0">{{ this.cardTitle }}</h3>
      </div>
      <!-- Card body -->
      <div class="card-body">
        <!-- Form groups used in grid -->
        <Form
          @submit="onSubmit"
          :validation-schema="schema"
          class="needs-validation"
        >
          <div class="row">
            <div v-if="news" class="col-xxxl-4">
              <h4 class="text-center mb-4">Imagen principal</h4>

              <image-uploader
                :image="news?.image_url === null ? '' : news.image_url"
                :showUploadButton="false"
                @imageLoaded="loadLogoImage"
              />
            </div>
            <div class="col-xxxl-auto">
              <div class="row">
                <div class="col-md-6 col-sm-12">
                  <div class="form-group">
                    <label class="control-label"> Título </label>
                    <base-input
                      name="title"
                      placeholder="Título"
                      :value="formData.title"
                    >
                    </base-input>
                  </div>
                </div>

                <div class="col-md-6 col-sm-12">
                  <div class="form-group">
                    <label class="control-label"> Fecha de Expiración </label>
                    <base-date name="expires_at" :value="formData.expires_at">
                    </base-date>
                  </div>
                </div>

                <div class="col">
                  <div class="form-group">
                    <label class="control-label"> Cuerpo </label>
                    <base-textarea
                      name="body"
                      placeholder="Cuerpo"
                      :type="'textarea'"
                      :value="formData.body"
                    >
                    </base-textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12 text-right">
              <base-button :loading="saving" native-type="submit" type="primary"
                >Guardar</base-button
              >
            </div>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import * as Yup from 'yup'
import { Form } from 'vee-validate'
import BaseDate from '../CustomInputs/BaseDate'
import BaseTextarea from '../CustomInputs/BaseTextarea'
import useNewsComposable from '../../composables/newsComposable'
import ImageUploader from '@/components/CustomInputs/ImageUploader'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import moment from 'moment'
import { computed } from 'vue'

export default {
  name: 'news-form',
  components: {
    BaseDate,
    BaseTextarea,
    Form,
    ImageUploader
  },
  props: {
    cardTitle: {
      type: String,
      default: 'Crear Noticia'
    },
    news: {
      type: Object,
      default: null
    }
  },
  setup(props, { emit }) {
    const { setFormData } = useNewsComposable()

    const store = useStore()

    const formData = setFormData(props.news)

    const schema = {
      title: Yup.string().required().label('Título'),
      body: Yup.string().required().label('Cuerpo'),
      expires_at: Yup.string().required().label('Fecha de Expiración')
    }

    const route = useRoute()

    function onSubmit(values) {
      const expires_at = moment(values.expires_at)
      const form = new FormData()
      form.append('id', route.params.id)
      form.append('body', values.body)
      form.append('expires_at', expires_at.format('YYYY-MM-DD'))
      form.append('title', values.title)
      form.append('image', formData.image)
      emit('save', form)
    }

    function loadLogoImage(image) {
      formData.image = image
    }

    const saving = computed(() => {
      return (store?.state.loader.store || store?.state.loader.update) ?? false
    })

    return {
      formData,
      loadLogoImage,
      onSubmit,
      saving,
      schema
    }
  }
}
</script>

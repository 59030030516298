import { createStore } from 'vuex'
import auth from './modules/auth'
import categories from './modules/categories'
import users from './modules/users'
import news from './modules/news'
import notifications from './modules/notifications'
import promotions from './modules/promotions'
import roles from './modules/roles'
import shops from './modules/shops'
import userTypes from './modules/userTypes'
import loader from './modules/loader'

export default createStore({
  modules: {
    auth,
    categories,
    loader,
    news,
    notifications,
    promotions,
    roles,
    shops,
    users,
    userTypes
  }
})

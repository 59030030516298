import ApiService from './api/base.service'

class userTypeService extends ApiService {
  constructor () {
    super('user-types')
  }

}

const userTypesService = new userTypeService()

export default userTypesService

<template>
  <div>
    <el-input
      class="base-textarea"
      autosize
      type="textarea"
      :rows="rows"
      :placeholder="placeholder"
      v-model="textareaValue"
      @change="handleChange"
    />
    <div>
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
import { ElInput } from 'element-plus'
import { useField } from 'vee-validate'
import { ref } from 'vue'

export default {
  name: 'base-textarea',
  components: {
    [ElInput.name]: ElInput
  },
  props: {
    value: {
      default: ''
    },
    name: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      default: ''
    },
    rows: {
      default: 4,
      type: Number
    }
  },
  setup(props) {
    const { value: inputValue, errorMessage, handleChange, meta } = useField(
      props.name,
      undefined,
      {
        initialValue: props.value
      }
    )

    const textareaValue = ref(props.value)

    return {
      textareaValue,
      handleChange,
      errorMessage,
      inputValue,
      meta
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .base-textarea textarea {
  height: auto !important;
}
</style>

<template>
  <div>
    <el-checkbox
      class="base-checkbox"
      v-model="checkboxValue"
      :label="label"
      :size="size"
      border
      @change="$emit('update:modelValue', checkboxValue)"
    />
  </div>
</template>

<script>
import { ElCheckbox } from 'element-plus'
import { useField } from 'vee-validate'
import { ref } from 'vue'

export default {
  name: 'base-checkbox',
  components: {
    ElCheckbox
  },
  props: {
    label: {
      default: 'Option'
    },

    name: {
      type: String,
      required: true
    },

    // large / default /small
    size: {
      default: 'default'
    },

    value: {
      default: false
    }
  },

  setup(props) {
    const { value: inputValue, errorMessage, handleChange, meta } = useField(
      props.name,
      undefined,
      {
        initialValue: props.value
      }
    )

    const checkboxValue = ref(props.value)

    return {
      checkboxValue,
      handleChange,
      errorMessage,
      inputValue,
      meta
    }
  }
}
</script>

<style scoped>
::v-deep .base-checkbox.el-checkbox.is-bordered {
  border: 1px solid #dcdfe6;
}

::v-deep .el-checkbox.is-bordered .el-checkbox__label {
  color: #525f7f;
}
</style>

<template>
  <div>
    <p class="card-category">Mostrando de {{ meta.from }} a {{ meta.to }} de un total de {{ meta.total }} {{ entityName }}</p>
  </div>  
</template>

<script>

export default {
  name: 'from-to-total',
  props: {
    entityName: {
      type: String,
      default: ''
    },
    meta: {
      required: true
    }
  },
}
</script>
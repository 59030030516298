<template>
  <base-nav
    container-classes="container-fluid"
    class="navbar-top navbar-expand"
    :class="{ 'bg-success navbar-dark': type === 'default' }"
  >
    <ul
      v-if="me?.role.name !== 'sudo'"
      class="mw-25 navbar-nav align-items-center mr-auto ml-md-0"
    >
      <div class="sidenav-header d-flex align-items-center">
        <a id="logo" class="home-logo logo-link" href="#">
          <img
            src="@/assets/images/logo-shopping-plaza.png"
            class="navbar-brand-img"
            alt="Sidebar logo"
          />
        </a>
      </div>
    </ul>
    <ul class="navbar-nav align-items-center ml-auto ml-md-0">
      <base-dropdown
        menu-on-right
        class="nav-item"
        tag="li"
        title-tag="a"
        title-classes="nav-link pr-0"
      >
        <template v-slot:title-container>
          <a href="#" class="nav-link-orange pr-0" @click.prevent>
            <div class="media align-items-center">
              <i class="fas fa-user-circle"></i>
              <div class="media-body ml-2 d-none d-lg-block">
                <span class="mb-0 text-sm font-weight-bold">{{
                  username
                }}</span>
              </div>
            </div>
          </a>
        </template>

        <div class="dropdown-header noti-title">
          <h6 class="text-overflow m-0">BIENVENIDO!</h6>
        </div>
        <!-- <a href="#!" class="dropdown-item">
          <i class="ni ni-single-02"></i>
          <span>Mi perfil</span>
        </a>
        <div class="dropdown-divider"></div> -->
        <button @click="logout" class="dropdown-item">
          <i class="ni ni-user-run"></i>
          <span>Logout</span>
        </button>
      </base-dropdown>
    </ul>
  </base-nav>
</template>
<script>
import { computed } from 'vue'
import { useAuth } from '@websanova/vue-auth/src/v3.js'
import { useStore } from 'vuex'
import BaseNav from '@/components/Navbar/BaseNav'

export default {
  setup() {
    const auth = useAuth()
    const store = useStore()

    const checkout = () => store.dispatch('auth/unsetUser')

    const username = computed(() => store.getters['auth/userName'])
    const me = computed(() => store.getters['auth/user'])

    function logout() {
      checkout()
      return auth.logout({
        redirect: { name: 'Login' }
      })
    }

    return {
      logout,
      me,
      username
    }
  },
  components: {
    BaseNav
  },
  props: {
    type: {
      type: String,
      default: 'default', // default|light
      description:
        'Look of the dashboard navbar. Default (Green) or light (gray)'
    }
  },
  computed: {
    routeName() {
      const { name } = this.$route
      return this.capitalizeFirstLetter(name)
    }
  },
  data() {
    return {
      activeNotifications: false,
      showMenu: false,
      searchModalVisible: false,
      searchQuery: ''
    }
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
    closeDropDown() {
      this.activeNotifications = false
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false)
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar)
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications
    }
  }
}
</script>

<style scoped>
#logo.home-logo .navbar-brand-img {
  max-width: 170px;
  background-color: white;
}

i.fas.fa-user-circle {
  font-size: 40px;
}
</style>

<template>
  <div class="col-10 card-wrapper">
    <div class="card mb-4">
      <div class="card-header">
        <h3 class="mb-0">{{ this.cardTitle }}</h3>
      </div>
      <div class="card-body">
        <Form
          @submit="onSubmit"
          :validation-schema="schema"
          class="needs-validation"
        >
          <div class="row">
            <div class="col-6">
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label class="control-label"> Título </label>
                    <base-input
                      name="title"
                      placeholder="Título"
                      :value="formData.title"
                    >
                    </base-input>
                  </div>
                </div>

                <div class="col-sm-12">
                  <div class="form-group">
                    <label class="control-label"> Texto </label>
                    <base-textarea
                      name="text"
                      placeholder="Texto"
                      :value="formData.text"
                    >
                    </base-textarea>
                  </div>
                </div>

                <div class="col-md-3 col-sm-6">
                  <div class="form-group">
                    <label class="control-label"> Frecuencia </label>
                    <base-select
                      v-model="formData.frequency"
                      :name="'frequency'"
                      :initialValue="formData.frequency"
                      :options="options"
                    />
                  </div>
                </div>

                <div class="col-md-6 col-sm-12">
                  <div v-if="showDateRagePicker" class="form-group">
                    <label class="control-label"> Rango de validez </label>
                    <base-date-range
                      :name="'from_to'"
                      :initialValue="formData.from_to"
                    />
                  </div>
                  <div v-else class="form-group">
                    <label class="control-label"> Fecha de Emisión </label>
                    <base-date name="once_date" :value="formData.once_date">
                    </base-date>
                  </div>
                </div>

                <div class="col-12">
                  <div class="form-group">
                    <label class="control-label"> Hora de Publicación </label>
                    <base-time-select
                      v-model="formData.time"
                      name="time"
                      :value="formData.time"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="row">
                <div class="col-12">
                  <label class="control-label">Asociar a:</label>

                  <el-radio-group v-model="formData.linkable_type">
                    <el-radio
                      v-for="option in linkableTypesOptions"
                      :key="option.name"
                      :label="option.value"
                      size="large"
                      border
                    >
                      {{ option.name }}
                    </el-radio>
                  </el-radio-group>

                  <div v-if="showAutocomplete">
                    <notifications-autocomplete
                      v-model="formData.linkable_id"
                      :linkableType="formData.linkable_type"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12 text-right">
              <base-button :loading="saving" native-type="submit" type="primary"
                >Guardar</base-button
              >
            </div>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import * as Yup from 'yup'
import { Form } from 'vee-validate'
import { computed } from 'vue'

import { ElRadio, ElRadioGroup } from 'element-plus'

import { useStore } from 'vuex'
import BaseSelect from '../CustomInputs/BaseSelect'
import BaseDateRange from '../CustomInputs/BaseDateRange'
import BaseDate from '../CustomInputs/BaseDate.vue'
import BaseTextarea from '../CustomInputs/BaseTextarea'
import BaseTimeSelect from '../CustomInputs/BaseTimeSelect'
import FormAutocomplete from '../Notifications/FormAutocomplete.vue'
import useNotification from '@/composables/notificationComposable'
import { getTime } from '@/util/common.js'

export default {
  name: 'promotion-form',
  components: {
    [ElRadio.name]: ElRadio,
    [ElRadioGroup.name]: ElRadioGroup,
    BaseDate,
    BaseDateRange,
    BaseTimeSelect,
    BaseSelect,
    BaseTextarea,
    Form,
    NotificationsAutocomplete: FormAutocomplete
  },
  props: {
    cardTitle: {
      type: String,
      default: 'Crear Usuario'
    },

    modelValue: {
      default: null
    }
  },
  setup(_, { emit }) {
    const { setFormData } = useNotification()

    const store = useStore()

    const formData = setFormData()

    const schema = {
      title: Yup.string().required().label('title'),
      text: Yup.string().required().label('text'),
      frequency: Yup.string().required().label('frequency'),
      from_to: Yup.array().of(Yup.date()).label('range'),
      once_date: Yup.date('hey hey').required().label('once_date')
    }

    function onSubmit(values) {
      const { linkable_type, linkable_id, time } = formData

      const payload =
        linkable_type && linkable_id
          ? {
              ...values,
              linkable_id,
              linkable_type,
              time: getTime(time)
            }
          : {
              ...values,
              time: getTime(time)
            }

      emit('save', payload)
    }

    const {
      getNotificationSelectOptions,
      linkableTypesOptions
    } = useNotification()

    const options = getNotificationSelectOptions()

    const showAutocomplete = computed(() => Boolean(formData.linkable_type))
    const showDateRagePicker = computed(() =>
      Boolean(formData.frequency !== 'once')
    )

    const saving = computed(() => {
      return (store?.state.loader.store || store?.state.loader.update) ?? false
    })

    return {
      formData,
      getNotificationSelectOptions,
      linkableTypesOptions,
      onSubmit,
      options,
      saving,
      schema,
      showAutocomplete,
      showDateRagePicker
    }
  }
}
</script>

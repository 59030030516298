const getters = {
  userTypesIds(state) {
    return state.resource && state.resource.types
      ? state.resource.types.map((ut) => ut.id)
      : null
  }
}

const actions = {
  userTypesUpdate({ commit, getters, rootGetters }, { id, tagNewValue }) {
    if (tagNewValue) {
      return getters.service.addUserType(getters.resource.id, id).then(() => {
        const userType = rootGetters['userTypes/list'].find((c) => c.id === id)
        commit('ADD_USERTYPE_TO_RESOURCE', userType)
      })
    } else {
      return getters.service
        .removeUserType(getters.resource.id, id)
        .then(() => {
          const userTypeIndex = getters.resource.types.map(cat => cat.id).indexOf(id)
          commit('REMOVE_USERTYPE_FROM_RESOURCE', userTypeIndex)
        })
    }
  }
}

const mutations = {
  ADD_USERTYPE_TO_RESOURCE: (state, userType) => {
    state.resource.types.push(userType)
  },
  REMOVE_USERTYPE_FROM_RESOURCE: (state, userTypeIndex) => {
    state.resource.types.splice(userTypeIndex,1)
  }
}

export default {
  getters,
  actions,
  mutations
}
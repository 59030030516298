import ApiService from './api/base.service'

class CategoryService extends ApiService {
  constructor () {
    super('categories')
  }

}

const categoriesService = new CategoryService()

export default categoriesService

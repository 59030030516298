<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div v-if="showForm" class="row justify-content-center">
				<shop-form
          :shop="shop"
          @mainImageUpdated="setNewImagesPaths"
          @logoImageUpdated="setNewImagesPaths"
          @save="updateShop"
        />
      </div>
    </div>
  </div>
</template>

<script>

  import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb'
  import ShopForm from '@/components/Forms/ShopForm'
  import { useStore } from 'vuex'
  import { ElNotification } from 'element-plus'
  import { useRouter, useRoute } from 'vue-router'
  import { ref, onMounted, computed } from 'vue'


	export default {
		components:{
      RouteBreadCrumb,
      ShopForm
    },
    setup () {
      const store = useStore()
      const router = useRouter()
      const route = useRoute()

      const showForm = ref(false)

      onMounted(() => {
        store.dispatch('shops/unsetResource')
        .then(() => {
          store.dispatch('shops/show', { id:route.params.id, params:{}})
            .then(() => showForm.value = true)
            .catch (() => router.push({name: 'Locales'}))
        })
      })

      const shop = computed(() => store.getters['shops/resource'])

      function updateShop(shop) {
        const updatedShop = {
          ...shop,
          id: route.params.id
        }
        store.dispatch('shops/update', updatedShop)
          .then(() => {
            ElNotification({
              title: 'Local Modificado',
              message: `El local ${shop.name} ha sido modificado con éxito`,
              type: 'success',
              duration: 6000
            })
          })
          .catch(() => {
            ElNotification({
              title: 'Error',
              message: 'No hemos podido modificar el local',
              type: 'error',
              duration: 6000
            })
          })
          .finally( () => {
            router.push({
              name: 'Locales'
            })
          })
      }

      function setNewImagesPaths(shop) {
        store.commit('shops/SET_RESOURCE', shop)
      }

      return {
        updateShop,
        setNewImagesPaths,
        showForm,
        shop
      }
    },	
	}
</script>

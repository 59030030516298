<template>
  <card body-classes="px-0 pb-1" class="no-border-card" footer-classes="pb-2">
    <template v-slot:header>
      <div class="d-flex justify-content-between align-items-center">
        <h3 class="mb-0">Listado de Noticias</h3>
      </div>
    </template>

    <div>
      <div
        class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
      >
        <!--    <div>
          <base-input placeholder="Search ..." prepend-icon="fas fa-search" v-model="searchQuery"></base-input>
        </div>
 -->
        <div class>
          <from-to-total
            v-if="meta && Object.keys(meta).length > 0"
            :entityName="'noticias'"
            :meta="meta"
          />
        </div>
      </div>
    </div>

    <el-table
      v-loading="loading"
      class="table-responsive table-flush word-break"
      header-row-class-name="thead-light"
      :data="news"
    >
      <el-table-column label="Título" prop="title" min-width="140px" sortable>
      </el-table-column>

      <el-table-column label="Expira" min-width="140px" sortable>
        <template v-slot="{ row }">
          {{ formatDate(row.expires_at) }}
        </template>
      </el-table-column>

      <el-table-column label="Acciones" min-width="170px" prop="role">
        <template v-slot="{ row }">
          <router-link
            v-if="can('store', 'news')"
            :to="{ name: 'Editar Noticia', params: { id: row.id } }"
            class="btn base-button btn-outline-primary btn-sm m-1"
          >
            <i class="fas fa-pen"></i>
          </router-link>

          <el-popconfirm
            v-if="can('destroy', 'news')"
            confirm-button-text="Si"
            cancel-button-text="No"
            icon-color="red"
            title="¿Está seguro de eliminar esta fila?"
            @confirm="confirmDeletion(row.id)"
          >
            <template #reference>
              <button
                title="Eliminar"
                class="btn base-button btn-outline-danger btn-sm m-1"
              >
                <i class="fas fa-trash"></i>
              </button>
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>

    <div class="card-footer py-4 d-flex justify-content-end">
      <base-pagination
        v-if="meta && Object.keys(meta).length > 0"
        v-model="currentPage"
        @update:modelValue="updatePage"
        :perPage="perPage"
        :total="meta.total"
      >
      </base-pagination>
    </div>
  </card>
</template>
<script>
import moment from 'moment'
import FromToTotal from '@/components/Layout/Lists/ShowingFromToTotal'
import usePagination from '@/composables/paginationComposable'
import { useAbility } from '@casl/vue'

import {
  ElTable,
  ElTableColumn,
  ElDropdownMenu,
  ElDropdownItem,
  ElDropdown,
  ElPopconfirm
} from 'element-plus'

export default {
  name: 'news-table',
  components: {
    FromToTotal,
    [ElTable.name]: ElTable,
    [ElTableColumn.name]: ElTableColumn,
    [ElDropdown.name]: ElDropdown,
    [ElDropdownItem.name]: ElDropdownItem,
    [ElDropdownMenu.name]: ElDropdownMenu,
    [ElPopconfirm.name]: ElPopconfirm
  },
  props: {
    loading: {
      default: false,
      type: Boolean
    },
    meta: {
      type: Object
    },
    news: {
      required: true
    }
  },
  setup(props, { emit }) {
    const { can } = useAbility()

    //Format columns
    function formatDate(dateString) {
      let newDate = moment(dateString, 'YYYY-MM-DD')
      return newDate.format('DD-MM-YYYY')
    }

    //Delete row
    function confirmDeletion(id) {
      emit('rowDeletionConfirmed', { id })
    }

    //Pagination
    const { currentPage, getListParameters, perPage } = usePagination()

    function updatePage() {
      emit('pageUpdated', getListParameters())
    }

    return {
      can,
      confirmDeletion,
      currentPage,
      formatDate,
      perPage,
      updatePage
    }
  }
}
</script>

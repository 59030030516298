<template>
  <el-dialog
    :model-value="showModal"
    title="Atención"
    width="30%"
    center
    @closed="emit('close')"
  >
    <div class="text-center text-break">
      <p>
        ¿Está seguro de eliminar al usuario
        <strong>
          {{ user?.username }}
        </strong>
        ?
      </p>
      <p v-if="user?.shop">
        El local
        <strong>
          <el-button type="text" @click="emit('goToShop')">
            {{ user?.shop.name }}
          </el-button>
        </strong>
        también será eliminado
      </p>
    </div>
    <template #footer>
      <div>
        <span class="dialog-footer">
          <el-button @click="emit('close')">Cancelar</el-button>
          <el-button type="danger" @click="emit('deleteConfirmed')"
            >Eliminar</el-button
          >
        </span>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import { ElButton, ElDialog } from 'element-plus'

export default {
  components: {
    ElButton,
    ElDialog
  },

  emits: {
    close: null,
    deleteConfirmed: null,
    goToShop: null
  },

  props: {
    showModal: {
      default: false
    },
    user: {
      default: null,
      type: [Object, null]
    }
  },

  setup(_, { emit }) {
    return {
      emit
    }
  }
}
</script>

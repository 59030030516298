<template>
  <modal 
    :show.sync="true"
    :showClose="false"
  >
    <template v-slot:header>
      <h5 class="modal-title ml-auto mr-auto text-center" id="modal-title-default">¡Promoción Aplicada!</h5>
    </template>
    <div class="mt-3">
      <p>
        La promoción {{ promotion.name }} se aplicó correctamente.
      </p>
      <p>
        <strong>Descuento:</strong> {{ promotion.discount }}% 
      </p>
    </div>
    <template v-slot:footer>
      <base-button type="secondary" class="ml-auto" @click="emitCloseButtonClicked">Close</base-button>
  </template>
  </modal>
</template>

<script>

import Modal from '@/components/Modal'
import BaseButton from '@/components/BaseButton'

export default {
  name:'applied-promotion-modal',
  components: {
    BaseButton,
    Modal
  },
  props: {
    promotion: {
      type: Object,
      required: true
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    function emitCloseButtonClicked() {
      emit('closeButtonClicked')
    }

    return {
      emitCloseButtonClicked
    }
  }
}
</script>

<style>

</style>
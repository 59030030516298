<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div v-if="showForm" class="row justify-content-center">
				<category-form
          :cardTitle="'Editar Categoría'"
          :category="category"
          @save="updateCategory"
        />
      </div>
    </div>
  </div>
</template>

<script>

  import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb'
  import CategoryForm from '@/components/Forms/CategoryForm'
  import { useStore } from 'vuex'
  import { ElNotification } from 'element-plus'
  import { useRouter, useRoute } from 'vue-router'
  import { ref, onMounted, computed } from 'vue'


	export default {
		components:{
      RouteBreadCrumb,
      CategoryForm
    },
    setup () {
      const store = useStore()
      const router = useRouter()
      const route = useRoute()

      const showForm = ref(false)

      onMounted(() => {
        store.dispatch('categories/unsetResource')
        .then(() => {
          store.dispatch('categories/show', { id:route.params.id, params:{}})
            .then(() => showForm.value = true)
            .catch (() => router.push({name: 'Categoría'}))
        })
      })

      const category = computed(() => store.getters['categories/resource'])

      function updateCategory(category) {
        const updatedCategory = {
          ...category,
          id: route.params.id
        }
        store.dispatch('categories/update', updatedCategory)
          .then(() => {
            ElNotification({
              title: 'Categoría Modificada',
              message: `La Categoría ${category.name} ha sido modificada con éxito`,
              type: 'success',
              duration: 6000
            })
          })
          .catch(() => {
            ElNotification({
              title: 'Error',
              message: 'No hemos podido modificar la categoría',
              type: 'error',
              duration: 6000
            })
          })
          .finally( () => {
            router.push({
              path: '/categorias'
            })
          })
      }

      return {
        showForm,
        updateCategory,
        category
      }
    },	
	}
</script>

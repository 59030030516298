<template>
  <div id="dashboard-layout-wrapper" class="wrapper">
    <!-- <notifications></notifications> -->
    <side-bar v-if="me?.role.name === 'sudo'">
      <template v-slot:links>
        <sidebar-item
          v-if="can('index', 'users')"
          :link="{
            name: 'Usuarios',
            icon: 'ni ni-single-02',
            path: '/usuarios'
          }"
        >
        </sidebar-item>
        <sidebar-item
          v-if="can('index', 'shops')"
          :link="{
            name: 'Locales',
            icon: 'ni ni-shop',
            path: '/locales'
          }"
        >
        </sidebar-item>
        <sidebar-item
          v-if="can('index', 'categories')"
          :link="{
            name: 'Categorias',
            icon: 'ni ni-bullet-list-67',
            path: '/categorias'
          }"
        >
        </sidebar-item>
        <sidebar-item
          v-if="can('index', 'promotions')"
          :link="{
            name: 'Promociones',
            icon: 'ni ni-diamond',
            path: '/promociones'
          }"
        >
        </sidebar-item>
        <sidebar-item
          v-if="can('index', 'news')"
          :link="{
            name: 'Noticias',
            icon: 'ni ni-map-big',
            path: '/noticias'
          }"
        >
        </sidebar-item>
        <sidebar-item
          v-if="can('sudo', 'notifications')"
          :link="{
            name: 'Notificaciones',
            icon: 'ni ni-send',
            path: '/notificaciones'
          }"
        >
        </sidebar-item>
      </template>
    </side-bar>
    <div class="main-content">
      <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>

      <div v-if="me" @click="$sidebar.displaySidebar(false)">
        <router-view></router-view>
      </div>
      <!-- <content-footer v-if="!$route.meta.hideFooter"></content-footer> -->
    </div>
  </div>
</template>
<script>
import PerfectScrollbar from 'perfect-scrollbar'
import 'perfect-scrollbar/css/perfect-scrollbar.css'

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`)
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className)
    }, 100)
  }
}

import DashboardNavbar from './DashboardNavbar.vue'
import { computed, onMounted } from 'vue'
import { useAbility } from '@casl/vue'
import { useStore } from 'vuex'

export default {
  components: {
    DashboardNavbar
  },
  setup() {
    const { can } = useAbility()

    const store = useStore()

    const me = computed(() => store.getters['auth/user'])

    onMounted(() => {
      if (!me.value) {
        store.dispatch('auth/me')
      }
    })

    return {
      can,
      me
    }
  },
  methods: {
    initScrollbar() {
      let isWindows = navigator.platform.startsWith('Win')
      if (isWindows) {
        initScrollbar('sidenav')
      }
    }
  },
  mounted() {
    this.initScrollbar()
  }
}
</script>
<style lang="scss" scoped>
@media print {
  #dashboard-layout-wrapper {
    visibility: hidden;
  }
  body #dashboard-layout-wrapper {
    height: auto;
  }
}
</style>

import axios from 'axios'

export default class BaseService {

  constructor (resource, prefix = 'api') {
    this.resource = resource
    this.prefix = prefix
    this.$http = axios
  }

  index (params = {}) {
    return this.$http.get(this.getResource(), { params: params }).catch(this.handleErrors)
  }

  show (id, params = {}) {
    return this.$http.get(this.getResource({ id }), { params: params }).catch(this.handleErrors)
  }

  store (payload, params = {}) {
    return this.$http.post(this.getResource(), this.map(payload), { params: params }).catch(this.handleErrors)
  }

  attach (id, payload, params = {}) {
    return this.$http.put(this.getResource({ id }), this.mapAttach(payload), { params: params }).catch(this.handleErrors)
  }

  update (id, payload, params = {}) {
    return this.$http.patch(this.getResource({ id }), this.map(payload), { params: params }).catch(this.handleErrors)
  }

  destroy (id, payload = {}) {
    return this.$http.delete(this.getResource({ id }), this.mapDelete(payload)).catch(this.handleErrors)
  }

  active (id, payload, params = {}) {
    return this.attach(id, payload, params)
  }

  attachPrefix (route) {
    return `${this.prefix}/${route}`
  }

  /** Build resource url */
  getResource (params = {}) {
    let route = this.resource

    if (Array.isArray(this.resource)) {
      params = Object.values(params)

      let index = 0

      route = this.resource.map((r) => {
        if (r.startsWith('{') && r.endsWith('}')) {
          return params[index++]
        }

        return r
      }).join('/')
    } else if (typeof this.resource === 'string' && params.id) {
      route = `${this.resource}/${params.id}`
    }

    return this.attachPrefix(route)
  }

  downloadPDF (response, filename) {
    let blob = new Blob([response.data], { type: 'application/pdf' })
    let link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)
    link.download = filename
    link.click()
    link.remove()
  }

  /** Map data to server */
  map (payload) {
    return payload
  }

  mapAttach (payload) {
    return payload
  }

  mapDelete (payload) {
    return {
      data: payload
    }
  }

  /** Handle errors */
  handleErrors (err) {
    if (err.response.status === 422) {
      return BaseService.errUnprocessableEntity(err)
    }
    return Promise.reject(err)
  }

  // Error 422
  static errUnprocessableEntity (err) {
    let errObj = err.response.data.errors
    let errors = []
    for (let prop in errObj) {
      // eslint-disable-next-line no-prototype-builtins
      if (errObj.hasOwnProperty(prop)) {
        errors.push(errObj[prop][0])
      }
    }
    return Promise.reject(errors.length > 0 ? errors : err)
  }
}

<template>
  <div>
    <el-date-picker
      class="base-date"
      v-model="datepickerValue"
      @change="handleChange"
    >
    </el-date-picker>
    <div>
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
import { ElDatePicker } from 'element-plus'
import { useField } from 'vee-validate'
import { ref } from 'vue'

export default {
  name: 'base-date',
  components: {
    [ElDatePicker.name]: ElDatePicker
  },
  props: {
    value: {
      default: null
    },
    name: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const { value: inputValue, errorMessage, handleChange, meta } = useField(
      props.name,
      undefined,
      {
        initialValue: props.value
      }
    )

    const datepickerValue = ref(props.value)

    return {
      datepickerValue,
      handleChange,
      errorMessage,
      inputValue,
      meta
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .base-date.el-date-editor.el-input {
  width: 100%;
  .el-input__inner {
    text-align: center;
  }
}
</style>

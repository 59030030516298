/*!

=========================================================
* Vue Argon Dashboard PRO - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import auth from './plugins/auth'
import http from './http'
import store from './store'
import ArgonDashboard from './plugins/argon-dashboard'
import 'element-plus/lib/theme-chalk/index.css'
import { abilitiesPlugin } from '@casl/vue'
import ability from './plugins/ability'

import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'

import { setLocale } from 'yup'

setLocale({
  mixed: {
    default: 'Campo no válido',
    required: 'Este campo es obligatorio',
    notType: ({ value }) => {
      if (value === null) {
        return 'Este campo es obligatorio'
      } else {
        return 'Error de tipo'
      }
    }
  },
  number: {
    default: 'Campo no válido',
    min: 'Debe ser mayor que ${min}'
  },
  string: {
    default: 'Campo no válido',
    email: 'Este campo debe tener un formato de mail',
    min: 'Debe ser mayor que ${min} caracteres'
  }
})

const options = { containerClassName: 'ct-notification' }

const appInstance = createApp(App)
appInstance.use(abilitiesPlugin, ability, {
  useGlobalProperties: true
})
appInstance.use(router)
appInstance.use(http)
appInstance.use(Toast, options)
appInstance.use(ArgonDashboard)
appInstance.use(auth)
appInstance.use(store)
appInstance.mount('#app')

import { reactive } from 'vue'

export default function () {

  function setFormData (user) {
    return reactive({
      email: user ? user.email : null,
      username: user ? user.username : null,
      password: user ? user.password : null,
      role_id: user ? user.role_id : null
    })
  }

  return {
    setFormData
  }
} 
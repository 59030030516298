import newsService from '../../services/news.service'
import crud from './utils/crud.util'

const state = {}

// getters
const getters = {
  allNewsSelect(state) {
    return (searchTerm) => {
      return state.all
        .filter((prom) => prom.title.toLowerCase().includes(searchTerm))
        .map((news) => ({
          expires_at: news.expires_at,
          name: news.title,
          value: news.id
        }))
    }
  },

  service() {
    return newsService
  }
}

// actions
const actions = {
  updateNews({ getters }, params) {
    return getters.service.updateNews(params.get('id'), params)
  }
}

// mutations
const mutations = {}

export default {
  namespaced: true,
  state: { ...state, ...crud.state },
  getters: { ...getters, ...crud.getters },
  actions: { ...actions, ...crud.actions },
  mutations: { ...mutations, ...crud.mutations }
}

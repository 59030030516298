import promotionsService from '../../services/promotions.service'
import crud from './utils/crud.util'
import userTypes from './utils/userTypes.utils'
import categories from './utils/categories.utils'

const state = {}

// getters
const getters = {
  allPromotionsSelect(state) {
    return (searchTerm) => {
      return state.all
        .filter((prom) => prom.name.toLowerCase().includes(searchTerm))
        .map((promotion) => ({
          description: promotion.description,
          image_url: promotion.image_url,
          name: promotion.name,
          value: promotion.id
        }))
    }
  },

  service() {
    return promotionsService
  }
}

// actions
const actions = {}

// mutations
const mutations = {}

export default {
  namespaced: true,
  state: { ...state, ...crud.state },
  getters: {
    ...getters,
    ...crud.getters,
    ...categories.getters,
    ...userTypes.getters
  },
  actions: {
    ...actions,
    ...crud.actions,
    ...categories.actions,
    ...userTypes.actions
  },
  mutations: {
    ...mutations,
    ...crud.mutations,
    ...categories.mutations,
    ...userTypes.mutations
  }
}

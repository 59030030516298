<template>
  <div class="mt-2 option-tag">
    <el-button
      :style="getStyle()"
      :class="tagValue ? 'tag-true' : 'tag-false'"
      class="option-tag mr-2"
      :loading="loading"
      @click="tagUpdate"
    >
      {{ label }}
      <template #loading>
        <div class="custom-loading">
          <svg class="circular" viewBox="-10, -10, 50, 50">
            <path
              class="path"
              d="
              M 30 15
              L 28 17
              M 25.61 25.61
              A 15 15, 0, 0, 1, 15 30
              A 15 15, 0, 1, 1, 27.99 7.5
              L 15 15
            "
              style="stroke-width: 4px; fill: rgba(0, 0, 0, 0)"
            ></path>
          </svg>
        </div>
      </template>
    </el-button>
  </div>
</template>

<script>
import { useStore } from 'vuex'
import { ElButton, ElNotification } from 'element-plus'
import { ref } from 'vue'

export default {
  name: 'option-tag',
  components: {
    ElButton
  },
  props: {
    entityGender: {
      required: true
    },
    entityName: {
      required: true
    },
    entityId: {
      required: true
    },
    label: {
      type: String,
      default: 'tag'
    },
    tagValue: {
      type: Boolean
    },
    theme: {
      default: 'green'
    },
    vuexResource: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const loading = ref(false)

    const store = useStore()

    function tagUpdate() {
      store
        .dispatch(props.vuexResource + 'Update', {
          id: props.entityId,
          tagNewValue: !props.tagValue
        })
        .then(() => {
          let action = 'agregada'
          if (props.entityGender === 'F') {
            action = props.tagValue ? 'agregada' : 'quitada'
          } else {
            action = props.tagValue ? 'agregado' : 'quitado'
          }

          ElNotification({
            title: `${props.entityName} ${action}!`,
            // message: 'La promoción ha sido creada con éxito',
            type: 'success',
            duration: 3000
          })
        })
    }

    function getStyle() {
      const defaultTrueColor = props.theme === 'green' ? '#173e36' : '#ff7729'
      const defaultTrueFontColor = '#FFF'

      const defaultFalseColor = '#FFF'
      const defaultFalseFontColor =
        props.theme === 'green' ? '#036c5e' : '#ff7729'

      const defaultOptions = {
        'background-color': props.tagValue
          ? defaultTrueColor
          : defaultFalseColor,
        color: props.tagValue ? defaultTrueFontColor : defaultFalseFontColor,
        'text-transform': 'uppercase'
      }
      return defaultOptions
    }

    return {
      getStyle,
      loading,
      tagUpdate
    }
  }
}
</script>

<style scoped>
.option-tag.tag-true:hover {
  transition: opacity 2s;
  opacity: 0.5;
}
</style>

import ApiService from './api/base.service'
import ability from '../plugins/ability'

class UserService extends ApiService {
  constructor() {
    super('users')
    this.permissions = null
  }

  addUserType(userId, typeId) {
    return this.$http
      .post(`${this.getResource({})}/${userId}/types/${typeId}`)
      .catch(this.handleErrors)
  }

  me() {
    return this.$http.get(`api/user`).catch(this.handleErrors)
  }

  parseUserData(response) {
    this.setPermissions(response)
    return response
  }

  removeUserType(userId, typeId) {
    return this.$http
      .delete(`${this.getResource({})}/${userId}/types/${typeId}`)
      .catch(this.handleErrors)
  }

  restore(id) {
    return this.attach(id)
  }

  setAbilityRules(user) {
    this.permissions = user.role.permissions.map((p) => p.name)
    const rules = []
    this.permissions.forEach((permission) => {
      const arr = permission.split('.')
      rules.push({
        subject: [arr[0]],
        action: [arr[1]]
      })
    })
    ability.update(rules)
  }

  setPermissions(user) {
    this.setAbilityRules(user)
    return this.permissions
  }

  forgotPassword(email) {
    return this.$http
      .post(`api/forgot-password`, { email })
      .catch(this.handleErrors)
  }

  resetPassword(payload) {
    return this.$http.post('api/reset-password', payload)
  }
}

const usersService = new UserService()

export default usersService

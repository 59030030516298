<template>
  <div>
    <!-- Header -->
    <div class="header bg-gradient-success py-7 py-lg-8 pt-lg-9">
      <div class="container">
        <div class="header-body text-center mb-5">
          <div class="row justify-content-center">
            <div class="col-xl-5 col-lg-6 col-md-8 px-5">
              <h1 class="text-white">Bienvenido!</h1>
            </div>
          </div>
        </div>
      </div>
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg
          x="0"
          y="0"
          viewBox="0 0 2560 100"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon
            class="fill-default"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <div class="container mt--8 pb-5">
      <div class="row justify-content-center">
        <div class="col-lg-5 col-md-7">
          <div class="card bg-secondary border-0 mb-0">
            <div class="card-body px-lg-5 py-lg-5">
              <div class="text-center text-muted mb-4">
                <small>Logueate ingresando tus credenciales:</small>
              </div>
              <Form @submit="loginDefault" :validation-schema="schema">
                <base-input
                  alternative
                  name="email"
                  addon-left-icon="ni ni-email-83"
                  placeholder="Email"
                  v-model="loginForm.email"
                >
                </base-input>

                <base-input
                  alternative
                  name="password"
                  addon-left-icon="ni ni-lock-circle-open"
                  type="password"
                  placeholder="Password"
                  :value="loginForm.password"
                >
                </base-input>

                <div class="error-container">
                  <p class="error-text text-center">
                    {{ errorMessage }}
                  </p>
                </div>

                <div class="text-center">
                  <base-button
                    class="my-4"
                    native-type="submit"
                    type="primary"
                    :loading="loading"
                  >
                    Ingresar
                  </base-button>
                </div>
              </Form>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-6">
              <router-link to="/forgot-password" class="text-light"
                ><small>¿Olvidaste tu contraseña?</small></router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Form } from 'vee-validate'
import * as Yup from 'yup'
import { useAuth } from '@websanova/vue-auth/src/v3.js'
import { useStore } from 'vuex'
import { ref, reactive } from 'vue'

export default {
  components: {
    Form
  },
  setup() {
    const auth = useAuth()
    const store = useStore()

    function onSubmit(values) {
      alert(JSON.stringify(values, null, 2))
    }

    const loginUser = (user) => store.dispatch('auth/setUser', user)

    const loading = ref(false)

    const loginForm = reactive({
      username: '',
      password: ''
    })

    const errorMessage = ref('')

    function loginDefault(loginForm) {
      errorMessage.value = ''

      loading.value = true

      auth
        .login({
          data: {
            username: loginForm.email,
            password: loginForm.password
          }
        })
        .then((res) => {
          loginUser(res.data)
        })
        .catch((err) => {
          errorMessage.value = err.response.data.message
        })
        .finally(() => (loading.value = false))
    }

    const schema = Yup.object().shape({
      fullName: Yup.string().required().label('The Full Name'),
      email: Yup.string().email().required().label('The Email'),
      password: Yup.string().min(5).required().label('The Password')
    })

    return {
      loading,
      loginDefault,
      loginForm,
      onSubmit,
      errorMessage,
      schema
    }
  }
}
</script>

<style lang="scss" scoped>
.error-text {
  color: red;
}
</style>

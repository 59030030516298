import { reactive } from 'vue'

export default function () {
  function setFormData(promotion) {
    return reactive({
      name: promotion ? promotion.name : null,
      description: promotion ? promotion.description : null,
      terms: promotion ? promotion.terms : null,
      discount: promotion ? promotion.discount : 1,
      from_to: promotion ? [promotion.from, promotion.to] : null,
      days: !promotion ? 0 : promotion.days ? promotion.days : 0,
      allDays: promotion ? !promotion.days : true,
      shops: promotion?.shops ? promotion.shops.map((shop) => shop.id) : []
    })
  }

  return {
    setFormData
  }
}

<template>
  <card body-classes="px-0 pb-1" class="no-border-card" footer-classes="pb-2">
    <template v-slot:header>
      <div class="d-flex justify-content-between align-items-center">
        <h3 class="mb-0">Listado de Locales</h3>
      </div>
    </template>

    <div>
      <div
        class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
      >
        <div>
          <search-input placeholder="Ingrese búsqueda" @search="handleSearch" />
        </div>
        <div class>
          <from-to-total
            v-if="meta && Object.keys(meta).length > 0"
            :entityName="'locales'"
            :meta="meta"
          />
        </div>
      </div>
    </div>

    <print-qr-modal
      v-if="selectedShop.shop"
      :shop="selectedShop.shop"
      @beforeClose="cleanSelectedShop"
    />

    <el-table
      v-loading="loading"
      id="shop-table"
      class="table-responsive table-flush word-break"
      header-row-class-name="thead-light"
      :data="shops"
      @sort-change="applySorting"
    >
      <el-table-column
        label="Nombre"
        prop="name"
        min-width="140px"
        sortable="custom"
      >
      </el-table-column>

      <el-table-column label="Descripción" prop="description" min-width="140px">
      </el-table-column>

      <el-table-column label="Usuario asociado" min-width="170px">
        <template v-slot="{ row }">
          {{ row.user?.username || '---' }}
        </template>
      </el-table-column>

      <el-table-column label="Acciones" min-width="170px" prop="role">
        <template v-slot="{ row }">
          <router-link
            v-if="can('store', 'shops')"
            :to="{ name: 'Editar Local', params: { id: row.id } }"
            class="btn base-button btn-outline-primary btn-sm m-1"
          >
            <i class="fas fa-pen"></i>
          </router-link>

          <button
            title="Ver QR"
            class="btn base-button btn-outline-info btn-sm m-1"
            @click="showQr(row)"
          >
            <i class="fas fa-qrcode"></i>
          </button>

          <el-popconfirm
            v-if="can('destroy', 'shops')"
            confirm-button-text="Si"
            cancel-button-text="No"
            icon-color="red"
            title="¿Está seguro de eliminar esta fila?"
            @confirm="confirmDeletion(row.id)"
          >
            <template #reference>
              <button
                title="Eliminar"
                class="btn base-button btn-outline-danger btn-sm m-1"
              >
                <i class="fas fa-trash"></i>
              </button>
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>

    <div class="card-footer py-4 d-flex justify-content-end">
      <base-pagination
        v-if="meta && Object.keys(meta).length > 0"
        v-model="currentPage"
        @update:modelValue="updatePage"
        :perPage="perPage"
        :total="meta.total"
      >
      </base-pagination>
    </div>
  </card>
</template>
<script>
import FromToTotal from '@/components/Layout/Lists/ShowingFromToTotal'
import usePagination from '@/composables/paginationComposable'
import printQrModal from '@/components/Shops/PrintQRModal'
import {
  ElTable,
  ElTableColumn,
  ElDropdownMenu,
  ElDropdownItem,
  ElDropdown,
  ElPopconfirm
} from 'element-plus'
import { reactive } from 'vue'
import { useAbility } from '@casl/vue'
import SearchInput from '@/components/Inputs/SearchInput'

export default {
  name: 'shops-table',
  components: {
    FromToTotal,
    printQrModal,
    [ElTable.name]: ElTable,
    [ElTableColumn.name]: ElTableColumn,
    [ElDropdown.name]: ElDropdown,
    [ElDropdownItem.name]: ElDropdownItem,
    [ElDropdownMenu.name]: ElDropdownMenu,
    [ElPopconfirm.name]: ElPopconfirm,
    SearchInput
  },
  props: {
    loading: {
      default: false,
      type: Boolean
    },
    meta: {
      type: Object
    },
    shops: {
      required: true
    }
  },
  setup(props, { emit }) {
    const { can } = useAbility()

    //Delete row
    function confirmDeletion(id) {
      emit('rowDeletionConfirmed', { id })
    }

    function handleSearch(searchTerm) {
      search.value = searchTerm
      updatePage()
    }

    function applySorting({ order, prop }) {
      sort.value.order = order
      sort.value.prop = prop
      updatePage()
    }

    //Pagination
    const {
      currentPage,
      getListParameters,
      perPage,
      search,
      sort
    } = usePagination()

    function updatePage() {
      emit('pageUpdated', getListParameters())
    }

    // Show QR

    const selectedShop = reactive({ shop: null })

    function showQr(shop) {
      selectedShop.shop = shop
    }

    function cleanSelectedShop() {
      selectedShop.shop = null
    }

    return {
      applySorting,
      can,
      cleanSelectedShop,
      confirmDeletion,
      currentPage,
      handleSearch,
      perPage,
      selectedShop,
      showQr,
      updatePage
    }
  }
}
</script>

<style lang="scss" scoped>
@media print {
  #shop-table {
    display: none;
  }
}
</style>

import { reactive } from 'vue'

export default function () {

  function setFormData (news) {
    return reactive({
      title: news ? news.title : null,
      body: news ? news.body : null,
      image: '',
      expires_at: news ? news.expires_at : null
    })
  }

  return {
    setFormData
  }
} 
<template>
  <div>
    <!-- Header -->
    <div class="header bg-gradient-success py-7 py-lg-8 pt-lg-9">
      <div class="container">
        <div class="header-body text-center mb-5">
          <div class="row justify-content-center">
            <div class="col-xl-5 col-lg-6 col-md-8 px-5">
              <h1 class="text-white">Recuperá tu cuenta</h1>
            </div>
          </div>
        </div>
      </div>
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg
          x="0"
          y="0"
          viewBox="0 0 2560 100"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon
            class="fill-default"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <div class="container mt--8 pb-5">
      <div class="row justify-content-center">
        <div class="col-lg-5 col-md-7">
          <div class="card bg-secondary border-0 mb-0">
            <div class="card-body px-lg-5 py-lg-5">
              <div class="text-center text-muted mb-4">
                <small>Ingresá tu correo electrónico:</small>
              </div>
              <Form @submit="forgotPassword" :validation-schema="schema">
                <base-input
                  alternative
                  name="email"
                  addon-left-icon="ni ni-email-83"
                  placeholder="Email"
                  v-model="formData.email"
                >
                </base-input>

                <div class="error-container">
                  <p class="error-text text-center">
                    {{ errorMessage }}
                  </p>
                </div>

                <div class="text-center">
                  <base-button
                    class="my-4"
                    native-type="submit"
                    type="primary"
                    :loading="loading"
                  >
                    Ingresar
                  </base-button>
                </div>
              </Form>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-6">
              <router-link to="/login" class="text-light"
                ><small>Volver al Login</small></router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Form } from 'vee-validate'
import * as Yup from 'yup'
import { useStore } from 'vuex'
import { ref, reactive } from 'vue'
import { ElMessageBox } from 'element-plus'
import { useRouter } from 'vue-router'

export default {
  components: {
    Form
  },
  setup() {
    const store = useStore()
    const router = useRouter()

    const errorMessage = ref('')
    const loading = ref(false)

    const formData = reactive({
      email: ''
    })

    function forgotPassword(formData) {
      errorMessage.value = ''
      loading.value = true
      store
        .dispatch('auth/forgotPassword', formData.email)
        .then(() => {
          ElMessageBox.alert(
            'Revisa tu correo para poder renovar tu contraseña',
            'Correo de recuperación enviado',
            {
              center: true,
              confirmButtonText: 'OK',
              showClose: false,
              'show-cancel-button': false,
              type: 'success'
            }
          ).finally(() => {
            router.push({
              path: `/login`
            })
          })
        })
        .catch(() => {
          errorMessage.value = 'Ha ingresado un email inválido'
        })
        .finally(() => (loading.value = false))
    }

    const schema = Yup.object().shape({
      email: Yup.string().email().required().label('Email')
    })

    return {
      errorMessage,
      forgotPassword,
      formData,
      loading,
      schema
    }
  }
}
</script>

<style lang="scss" scoped>
.error-text {
  color: red;
}
</style>

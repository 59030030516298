import usersService from '../../services/users.service'

const state = {
  user: null
}

// getters
const getters = {
  logged: (state) => {
    return Boolean(state.user)
  },

  myShop: (state) => {
    return state.user && state.user.shop ? state.user.shop : null
  },

  service: () => {
    return usersService
  },

  user: (state) => {
    return state.user
  },

  userName: (state) => {
    return state.user ? state.user.username : ''
  }
}

// actions
const actions = {
  setUser({ commit, state }, user) {
    commit('setUser', user)
  },

  me({ commit, getters }) {
    getters.service.me().then((res) => {
      commit('setUser', res.data)
    })
  },

  unsetUser({ commit, state }) {
    commit('setUser', null)
  },

  forgotPassword({ getters }, email) {
    return getters.service.forgotPassword(email)
  },

  resetPassword({ getters }, payload) {
    return getters.service.resetPassword(payload)
  }
}

// mutations
const mutations = {
  setUser(state, user) {
    state.user = user
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

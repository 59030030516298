import { reactive } from 'vue'

export default function () {
  function setFormData() {
    return reactive({
      title: null,
      text: null,
      frequency: 'once',
      from_to: null,
      linkable_type: null,
      linkable_id: null,
      once_date: new Date(),
      time: new Date(2023, 5, 1, 9)
    })
  }

  const allNotifications = {
    once: 'Única vez',
    daily: 'Diaria',
    weekly: 'Semanal',
    monthly: 'Mensual'
  }

  const linkableTypesOptions = [
    {
      name: 'Ninguna entidad',
      value: null
    },
    {
      name: 'Noticias',
      value: 'news'
    },
    {
      name: 'Promociones',
      value: 'promotion'
    }
  ]

  function getNotificationSelectOptions() {
    const properties = Object.getOwnPropertyNames(allNotifications)
    return properties.map((a) => {
      return { label: allNotifications[a], value: a }
    })
  }

  return {
    allNotifications,
    getNotificationSelectOptions,
    linkableTypesOptions,
    setFormData
  }
}

<template>
  <card body-classes="px-0 pb-1" class="no-border-card" footer-classes="pb-2">
    <template v-slot:header>
      <div class="d-flex justify-content-between align-items-center">
        <h3 class="mb-0">Listado de Usuarios</h3>
      </div>
    </template>

    <div>
      <div
        class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
      >
        <div>
          <search-input placeholder="Ingrese búsqueda" @search="handleSearch" />
        </div>

        <div class>
          <from-to-total
            v-if="meta && Object.keys(meta).length > 0"
            :entityName="'usuarios'"
            :meta="meta"
          />
        </div>
      </div>
    </div>

    <el-table
      v-loading="loading"
      class="table-responsive table-flush word-break"
      header-row-class-name="thead-light"
      :data="users"
      @sort-change="applySorting"
    >
      <el-table-column
        label="Usuario"
        min-width="140px"
        sortable="custom"
        prop="username"
      >
      </el-table-column>

      <el-table-column
        label="Email"
        min-width="140px"
        prop="email"
        sortable="custom"
      >
      </el-table-column>

      <el-table-column label="Rol" min-width="170px" prop="role">
        <template v-slot="{ row }">
          {{ row.role.label }}
        </template>
      </el-table-column>

      <el-table-column label="Acciones" min-width="170px" prop="role">
        <template v-slot="{ row }">
          <router-link
            v-if="can('store', 'users')"
            :to="{ name: 'Editar Usuario', params: { id: row.id } }"
            class="btn base-button btn-outline-primary btn-sm m-1"
          >
            <i class="fas fa-pen"></i>
          </router-link>
          <button
            title="Eliminar"
            class="btn base-button btn-outline-danger btn-sm m-1"
            @click="showDeleteModal(row)"
          >
            <i class="fas fa-trash"></i>
          </button>
        </template>
      </el-table-column>
    </el-table>

    <div class="card-footer py-4 d-flex justify-content-end">
      <base-pagination
        v-if="meta && Object.keys(meta).length > 0"
        v-model="currentPage"
        @update:modelValue="updatePage"
        :perPage="perPage"
        :total="meta.total"
      >
      </base-pagination>
    </div>
    <delete-user-modal
      :show-modal="state.showDeleteModal"
      :user="state.selectedUser"
      @close="closeDeleteModal"
      @goToShop="handleGoToShop"
      @deleteConfirmed="handleDeleteConfirmation"
    />
  </card>
</template>
<script>
import FromToTotal from '@/components/Layout/Lists/ShowingFromToTotal'
import DeleteUserModal from '@/components/Modal/DeleteUserModal'
import usePagination from '@/composables/paginationComposable'
import { useAbility } from '@casl/vue'
import { reactive } from 'vue'
import { useRouter } from 'vue-router'
import SearchInput from '@/components/Inputs/SearchInput'

import {
  ElTable,
  ElTableColumn,
  ElDropdownMenu,
  ElDropdownItem,
  ElDropdown,
  ElPopconfirm
} from 'element-plus'

export default {
  name: 'users-table',
  components: {
    FromToTotal,
    [ElTable.name]: ElTable,
    [ElTableColumn.name]: ElTableColumn,
    [ElDropdown.name]: ElDropdown,
    [ElDropdownItem.name]: ElDropdownItem,
    [ElDropdownMenu.name]: ElDropdownMenu,
    [ElPopconfirm.name]: ElPopconfirm,
    DeleteUserModal,
    SearchInput
  },
  props: {
    loading: {
      default: false,
      type: Boolean
    },
    meta: {
      type: Object
    },
    users: {
      required: true
    }
  },
  setup(_, { emit }) {
    const { can } = useAbility()

    const router = useRouter()

    //Delete row
    function confirmDeletion(id) {
      emit('rowDeletionConfirmed', { id })
    }

    //Pagination and filers
    const {
      currentPage,
      getListParameters,
      perPage,
      search,
      sort
    } = usePagination()

    const state = reactive({
      selectedUser: null,
      showDeleteModal: false
    })

    function handleSearch(searchTerm) {
      search.value = searchTerm
      updatePage()
    }

    function applySorting({ order, prop }) {
      sort.value.order = order
      sort.value.prop = prop
      updatePage()
    }

    function updatePage() {
      emit('pageUpdated', getListParameters())
    }

    function showDeleteModal(user) {
      state.selectedUser = user
      state.showDeleteModal = true
    }

    function closeDeleteModal() {
      state.showDeleteModal = false
      state.selectedUser = null
    }

    function handleGoToShop() {
      const shopId = state.selectedUser.shop.id
      closeDeleteModal()
      router.push({
        name: 'Editar Local',
        params: {
          id: shopId
        }
      })
    }

    function handleDeleteConfirmation() {
      emit('rowDeletionConfirmed', state.selectedUser)
      closeDeleteModal()
    }

    return {
      applySorting,
      can,
      confirmDeletion,
      currentPage,
      closeDeleteModal,
      handleGoToShop,
      handleDeleteConfirmation,
      handleSearch,
      perPage,
      showDeleteModal,
      state,
      updatePage
    }
  }
}
</script>

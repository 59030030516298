<template>
  <el-dialog
    v-model="dialogVisible"
    :title="'QR de ' + shop.name"
    width="30%"
    center
    @closed="handleClose"
  >
    <div class="d-flex justify-content-center" v-html="qr"></div>

    <div id="printable-qr" class="d-none d-print-block">
      <div id="printable-title" class="mb-5 printable row">
        <div class="col">
          <h1 class="text-center">{{ shop.name }}</h1>
        </div>
      </div>
      <div class="d-flex justify-content-center row" v-html="qr"></div>
    </div>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="hideModal()">Salir</el-button>
        <el-button type="primary" @click="printQr">Imprimir</el-button>
        <el-button type="secondary" @click="exportImage"
          >Exportar Imagen</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { ref, onMounted, computed } from 'vue'
import { ElButton, ElDialog } from 'element-plus'
import { useStore } from 'vuex'

export default {
  name: 'print-qr-modal',
  components: {
    ElButton,
    ElDialog
  },
  props: {
    shop: {
      type: Object,
      required: true
    }
  },
  setup(props, { emit }) {
    const store = useStore()

    const dialogVisible = ref(false)

    onMounted(() => {
      fetchShopQR()
    })

    function fetchShopQR() {
      store.dispatch('shops/getQrCode', props.shop.id).then(() => show())
    }

    function exportImage() {
      var svg = document.querySelector('svg')
      var canvas = document.createElement('canvas')
      canvas.height = 350
      canvas.width = 350
      var ctx = canvas.getContext('2d')
      var data = new XMLSerializer().serializeToString(svg)
      var DOMURL = window.URL || window.webkitURL || window

      var img = new Image()
      var svgBlob = new Blob([data], { type: 'image/svg+xml;charset=utf-8' })
      var url = DOMURL.createObjectURL(svgBlob)

      img.onload = function () {
        ctx.drawImage(img, 0, 0)
        DOMURL.revokeObjectURL(url)

        var imgURI = canvas
          .toDataURL('image/png')
          .replace('image/png', 'image/octet-stream')

        triggerDownload(imgURI)
      }

      img.src = url
    }

    function triggerDownload(imgURI) {
      var evt = new MouseEvent('click', {
        view: window,
        bubbles: false,
        cancelable: true
      })

      var a = document.createElement('a')
      a.setAttribute('download', `${props.shop.name}_QR.png`)
      a.setAttribute('href', imgURI)
      a.setAttribute('target', '_blank')
      a.dispatchEvent(evt)
    }

    function show() {
      dialogVisible.value = true
    }

    function hideModal() {
      dialogVisible.value = false
    }

    function handleClose() {
      emit('beforeClose')
    }

    function printQr() {
      window.print()
    }

    const qr = computed(() => store.getters['shops/qrCode'])

    return {
      dialogVisible,
      exportImage,
      handleClose,
      hideModal,
      printQr,
      qr,
      show
    }
  }
}
</script>

<style lang="scss" scoped>
@media print {
  #printable-qr,
  #printable-qr * {
    visibility: visible;
  }
  #printable-qr {
    position: absolute;
    left: 0;
    top: 0;
    page-break-after: avoid;
  }
}
</style>

import { createAuth } from '@websanova/vue-auth/src/v3.js'
import driverAuthBearer from './driverOAuthBearer'
import driverHttpAxios from '@websanova/vue-auth/src/drivers/http/axios.1.x.js'
import driverRouterVueRouter from '@websanova/vue-auth/src/drivers/router/vue-router.2.x.js'

import authService from '../services/users.service'

export default (app) => {
  app.use(
    createAuth({
      plugins: {
        http: app.axios,
        router: app.router
      },
      drivers: {
        http: driverHttpAxios,
        auth: driverAuthBearer,
        router: driverRouterVueRouter
      },
      options: {
        stores: ['storage', 'cookie'],
        tokenDefaultKey: 'auth_access_token',
        rolesKey: 'role.name',
        loginData: {
          url: 'oauth/token',
          method: 'POST',
          redirect: '/',
          fetchUser: true,
          staySignedIn: true,
          tokenName: 'access_token',
          data: {
            grant_type: 'password',
            client_id: process.env.VUE_APP_PASSPORT_CLIENT_ID,
            client_secret: process.env.VUE_APP_PASSPORT_CLIENT_SECRET,
            scope: ''
          }
        },
        refreshData: {
          url: 'oauth/token',
          method: 'POST',
          enabled: false,
          interval: process.env.MIX_PASSPORT_ACCESS_TOKEN_EXPIRES_IN || 30,
          tokenName: 'refresh_token',
          data: {
            grant_type: 'refresh_token',
            client_id: process.env.MIX_PASSPORT_CLIENT_ID,
            client_secret: process.env.MIX_PASSPORT_CLIENT_SECRET,
            scope: ''
          }
        },
        registerData: {
          url: 'auth/register',
          method: 'POST',
          redirect: '/ingresar',
          autoLogin: false
        },
        fetchData: {
          url: 'api/user',
          method: 'GET',
          enabled: true
        },
        logoutData: {
          redirect: '/ingresar'
          // success () {
          //   this.token(this.options.refreshData.tokenName, null)
          // }
        },
        forbiddenRedirect: {
          path: '/login'
        },
        parseUserData: (response) => {
          return authService.parseUserData(response)
        }
      }
    })
  )
}

import { reactive } from 'vue'

export default function () {
  function setFormData(shop) {
    return reactive({
      name: shop ? shop.name : null,
      description: shop ? shop.description : null,
      user_id: shop ? shop.user_id : null,
      validate_code: shop ? Boolean(shop.validate_code) : true
    })
  }

  return {
    setFormData
  }
}

<template>
  <div class="col-10 card-wrapper">
    <div class="card mb-4">
      <div class="card-header">
        <h3 class="mb-0">{{ this.cardTitle }}</h3>
      </div>
      <div class="card-body">
        <Form
          @submit="onSubmit"
          :validation-schema="schema"
          class="needs-validation"
        >
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label class="control-label"> Nombre </label>
                <base-input
                  name="name"
                  placeholder="Nombre"
                  :value="formData.name"
                >
                </base-input>
              </div>
            </div>

            <div class="col-12">
              <div class="form-group">
                <label class="control-label"> Descripción </label>
                <base-textarea
                  name="description"
                  placeholder="Descripción"
                  :value="formData.description"
                >
                </base-textarea>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12 text-right">
              <base-button :loading="saving" native-type="submit" type="primary"
                >Guardar</base-button
              >
            </div>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import * as Yup from 'yup'
import { computed } from 'vue'
import BaseTextarea from '../CustomInputs/BaseTextarea'
import { Form } from 'vee-validate'
import { useStore } from 'vuex'
import useCategoryComposable from '../../composables/categoryComposable'

export default {
  name: 'category-form',
  components: {
    Form,
    BaseTextarea
  },
  props: {
    cardTitle: {
      type: String,
      default: 'Crear Usuario'
    },
    category: {
      type: Object,
      default: null
    }
  },
  setup(props, { emit }) {
    const { setFormData } = useCategoryComposable()

    const store = useStore()

    const formData = setFormData(props.category)

    const schema = {
      name: Yup.string().required().label('Nombre'),
      description: Yup.string().required().label('Descripción')
    }

    const saving = computed(() => {
      return (store?.state.loader.store || store?.state.loader.update) ?? false
    })

    function onSubmit(values) {
      emit('save', values)
    }

    return {
      formData,
      onSubmit,
      saving,
      schema
    }
  }
}
</script>

<template>
  <div>
    <div class="row d-flex justify-content-center mb-3">
      <el-upload
        :limit="1"
        ref="imageUploader"
        class="avatar-uploader"
        :action="'undefined'"
        :class="{ 'image-loaded': imageLoaded }"
        :multiple="false"
        :show-file-list="false"
        :auto-upload="false"
        :drag="true"
        @input="onImageInput"
      >
        <template #default>
          <div
            v-if="imageSrc.length === 0"
            class="uploader-default-container mw-100"
          >
            <i class="ni ni-fat-add"></i>
          </div>
          <img v-else class="img-fluid" :src="imageSrc" alt="test" />
        </template>
      </el-upload>
    </div>

    <div class="text-center">
      <base-button
        v-show="showUploadButton"
        class="btn btn-primary"
        :disabled="!imageLoaded"
        :loading="loading"
        @click="uploadImage"
      >
        Subir Imagen
      </base-button>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { ElUpload } from 'element-plus'
import BaseButton from '@/components/BaseButton'

export default {
  name: 'image-uploader',
  components: {
    BaseButton,
    ElUpload
  },
  props: {
    image: {
      default: ''
    },
    loading: {
      default: false
    },
    showUploadButton: {
      default: true
    }
  },
  setup(props, { emit }) {
    const imageLoaded = ref(false)

    function uploadImage() {
      emit('uploadImage', imageUploader.value.uploadFiles[0].raw)
    }

    const imageSrc = ref(props.image)

    function onImageInput(event) {
      if (event.target.files[0]) {
        imageSrc.value = URL.createObjectURL(event.target.files[0])
        imageLoaded.value = true
        emit('imageLoaded', event.target.files[0])
      } else {
        imageLoaded.value = false
      }
    }

    const imageUploader = ref()

    return {
      imageLoaded,
      imageSrc,
      imageUploader,
      onImageInput,
      uploadImage
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .avatar-uploader.image-loaded .el-upload {
  border: none;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

::v-deep .avatar-uploader .el-upload-dragger {
  display: flex;
  justify-content: center;
  align-items: center;
  i {
    font-size: 25px;
  }
}
</style>

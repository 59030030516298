import ApiService from './api/base.service'
import moment from 'moment'
class PromotionService extends ApiService {
  constructor () {
    super('promotions')
  }

  addCategory (promotionId, categoryId) {
    return this.$http.post(`${this.getResource({})}/${promotionId}/categories/${categoryId}`).catch(this.handleErrors)
  }

  removeCategory (promotionId, categoryId) {
    return this.$http.delete(`${this.getResource({})}/${promotionId}/categories/${categoryId}`).catch(this.handleErrors)
  }

  addUserType (promotionId, typeId) {
    return this.$http.post(`${this.getResource({})}/${promotionId}/user-types/${typeId}`).catch(this.handleErrors)
  }

  removeUserType (promotionId, typeId) {
    return this.$http.delete(`${this.getResource({})}/${promotionId}/user-types/${typeId}`).catch(this.handleErrors)
  }

  uploadImage (promotionId, formData) {
    return this.$http.post(`${this.getResource({})}/${promotionId}/image`, formData).catch(this.handleErrors)
  }

  map (payload) {
    const from = moment(payload.from_to[0])
    const to = moment(payload.from_to[1])
    return {
      ...payload,
      from: from.format('YYYY-MM-DD'),
      to: to.format('YYYY-MM-DD'),
      day: payload.day === 0 ? null : payload.day,
      from_to: null
    }
  }
}

const promotionService = new PromotionService()

export default promotionService

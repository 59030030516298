import ApiService from './api/base.service'

class RoleService extends ApiService {
  constructor () {
    super('roles')
  }

  restore (id) {
    return this.attach(id)
  }

  map (payload) {
    return {
      name: payload.name
    }
  }
}

const rolesService = new RoleService()

export default rolesService

<template>
  <div class="col-10 card-wrapper">
    <div class="card mb-4">
      <div class="card-header">
        <h3 class="mb-0">{{ this.cardTitle }}</h3>
      </div>
      <div class="card-body">
        <Form
          @submit="onSubmit"
          :validation-schema="schema"
          class="needs-validation"
        >
          <div class="row">
            <div class="col-md-6 col-sm-12">
              <div class="form-group">
                <label class="control-label"> Email </label>
                <base-input
                  name="email"
                  placeholder="Email"
                  :value="formData.email"
                >
                </base-input>
              </div>
            </div>

            <div class="col-md-6 col-sm-12">
              <div class="form-group">
                <label class="control-label"> Username </label>
                <base-input
                  name="username"
                  placeholder="Username"
                  :value="formData.username"
                >
                </base-input>
              </div>
            </div>

            <div v-show="!user" class="col-md-6 col-sm-12">
              <div class="form-group">
                <label class="control-label"> Password </label>
                <base-input
                  name="password"
                  placeholder="Password"
                  type="password"
                  :value="formData.password"
                >
                </base-input>
              </div>
            </div>

            <div class="col-md-6 col-sm-12">
              <div class="form-group">
                <label class="control-label"> Rol </label>
                <base-select
                  :name="'role_id'"
                  :initialValue="formData.role_id"
                  :options="roles"
                />
              </div>
            </div>

            <div v-if="user && userTypeSelectedValues" class="col-12">
              <div class="form-group">
                <label class="control-label"> Tipos de Usuario </label>
                <tags-selector
                  :entityName="'Tipo de Usuario'"
                  :entityPluralName="'Tipos de Usuarios'"
                  :entityGender="'M'"
                  :options="userTypeOptions"
                  :selectedValues="userTypeSelectedValues"
                  :theme="'blue'"
                  :vuexResource="'users/userTypes'"
                />
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12 text-right">
              <base-button :loading="saving" native-type="submit" type="primary"
                >Guardar</base-button
              >
            </div>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import * as Yup from 'yup'
import { reactive } from 'vue'
import { Form } from 'vee-validate'
import { useStore } from 'vuex'
import { onMounted, computed } from 'vue'
import BaseSelect from '../CustomInputs/BaseSelect'
import TagsSelector from '../CustomInputs/TagsSelector'
import useUserComposable from '../../composables/userComposable'

export default {
  name: 'user-form',
  components: {
    BaseSelect,
    Form,
    TagsSelector
  },
  props: {
    cardTitle: {
      type: String,
      default: 'Crear Usuario'
    },
    user: {
      type: Object,
      default: null
    }
  },
  setup(props, { emit }) {
    //Basic Form

    const { setFormData } = useUserComposable()

    const store = useStore()

    const formData = setFormData(props.user)

    const roles = computed(() => store.getters['roles/selectList'])

    const baseSchema = {
      username: Yup.string().required().label('Username'),
      email: Yup.string().email().required().label('The Email')
    }

    const schema = props.user
      ? reactive(Yup.object().shape({ ...baseSchema }))
      : reactive(
          Yup.object().shape({
            ...baseSchema,
            password: Yup.string().min(8).required().label('The Password')
          })
        )

    onMounted(() => {
      const params = {
        sort: 'name'
      }
      store.dispatch('roles/list')
      store.dispatch('userTypes/list', params)
    })

    function onSubmit(values) {
      emit('save', values)
    }

    const saving = computed(() => {
      return (store?.state.loader.store || store?.state.loader.update) ?? false
    })

    //Add and Remove userTypes to user

    const userTypeSelectedValues = computed(
      () => store.getters['users/userTypesIds']
    )
    const userTypeOptions = computed(
      () => store.getters['userTypes/selectList']
    )

    return {
      formData,
      onSubmit,
      roles,
      saving,
      schema,
      userTypeOptions,
      userTypeSelectedValues
    }
  }
}
</script>
